import { Typography } from '@mui/material'
import { ConfirmationPopUp } from '@smartb/g2'
import { useOrganizationDisable2 } from '@smartb/g2-i2-v2'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {imConfig} from "@tracabois/domain";
import {organizationRoutes} from "../../route";
import {useExtendedAuth} from "../../../auth";

interface DisableOrganizationPopupProps {
    open: boolean
    onClose: () => void
    organizationId: string
}

export const DisableOrganizationPopup = (props: DisableOrganizationPopupProps) => {
    const { open, onClose, organizationId } = props
    const { t } = useTranslation()
    const { keycloak } = useExtendedAuth()
    const disableOrganization = useOrganizationDisable2({apiUrl: imConfig.orgUrl, jwt: keycloak.token})
    const navigate = useNavigate()
    const onDisableOrganization = useCallback(
        async () => {
            if (organizationId) {
                await disableOrganization.mutateAsync({
                  id: organizationId,
                  disabledBy: undefined,
                  userAttributes: undefined,
                  anonymize: false,
                  attributes: undefined
                })
              organizationRoutes.goto.organizationTable(navigate)
            }
            // onClose()
        },
        [disableOrganization.mutateAsync, organizationId, onClose, navigate],
    )

    return (
        <ConfirmationPopUp variant='deletion' open={open} onClose={onClose} onConfirm={onDisableOrganization} validateText={t("confirm")} cancelText={t("cancel")}>
            <Typography align="center">{t("organization.disableWarning")}</Typography>
        </ConfirmationPopUp>
    )
}
