import {useAuth} from "@smartb/g2-providers";

export const LogoutPage = () => {
  const {keycloak} = useAuth();
  keycloak.logout({
    redirectUri: `${window.location.protocol}//${window.location.host}`
  })
  return (
   <div></div>
  );
}
