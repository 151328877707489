import {
  RemovalZone,
  RemovalZoneUpdateCommand
} from "../../model/removalZone";
import {usePageQuery} from "@tracabois/components";
import {
  useRemovalZonePageQuery,
  useRemovalZoneUpdateMutation
} from "../../api/RemovalZoneApi";
import {useWoodSpeciesPageQuery} from "../../../../referentiels/woodSpecies";
import {allPage} from "@tracabois/utils";
import {RemovalZoneForm} from "../form/RemovalZoneForm";
import {FeatureCollection} from "geojson";
import {useCallback, useEffect} from "react";
import {FormComposableState, useFormComposable} from "@smartb/g2";
import {DropZonePictureAction} from "../../../brandOrganization";
import {useDropZonePicture} from "@smartb/g2-fs";
import {PlatformSubSection} from "@tracabois/template";

export interface RemovalZoneFormSectionProps {
  title: string
  item?: Partial<RemovalZone>
  geoJson?: FeatureCollection<any, any>
  onFormStateReady: (formState: FormComposableState) => void
}

export const RemovalZoneFormSection = (props: RemovalZoneFormSectionProps) => {
  const {title, item, geoJson, onFormStateReady} = props
  const removalZonePageQuery = usePageQuery(useRemovalZonePageQuery);
  const [updateRemovalZone] = useRemovalZoneUpdateMutation()
  // const [feedback, setFeedback] = useState<boolean | undefined>(undefined)
  const useGetWoodSpeciesQuery = useWoodSpeciesPageQuery({
    pagination: allPage
  })
  const imageDropZone: DropZonePictureAction = useDropZonePicture(item?.img?.url)
  const onSubmitMemoized = useCallback(
    async (values: RemovalZoneUpdateCommand) => {
      let updatedGeoJson: FeatureCollection<any, any> | undefined
      if (geoJson !== undefined){
        updatedGeoJson = JSON.parse(JSON.stringify(geoJson))
        if (updatedGeoJson !== undefined){
          updatedGeoJson.features[0].geometry.coordinates = [
            values?.geo?.longitude, 
            values?.geo?.latitude
          ]
        }
      }
      const cmd: RemovalZoneUpdateCommand = {
        ...item,
        ...values,
        img: imageDropZone.image,
        geoJson: updatedGeoJson
      }
      // const feedback = await onUpdate(object)
      // setFeedback(feedback)
      await updateRemovalZone(cmd)
      removalZonePageQuery.refetch()
      return true
    },
    [item, geoJson, imageDropZone.image]
  )

  const formState = useFormComposable<RemovalZoneUpdateCommand>({
    onSubmit: onSubmitMemoized,
    formikConfig: {
      initialValues: {
        ...item,
        img: imageDropZone.image
      }
    },
  })
  
  useEffect(() => {
    if (geoJson != undefined && geoJson.features[0]) {
      formState.setFieldValue("geo.latitude", geoJson.features[0].geometry.coordinates[1])
      formState.setFieldValue("geo.longitude", geoJson.features[0].geometry.coordinates[0])
    }
  }, [geoJson])

  useEffect(() => onFormStateReady(formState), [])

  return (
    <PlatformSubSection title={title}>
      <RemovalZoneForm
        item={item}
        formState={formState}
        woodSpecies={useGetWoodSpeciesQuery.data?.items || []}
        imageDropZone={imageDropZone}
        readonlyOrganization
      />
    </PlatformSubSection>
  )
};
