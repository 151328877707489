import {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {RealizationMoeTable} from "../../table/RealizationMoeTable";
import {RealizationMoeAddFormPopup} from "../../form/moeCreateForm/RealizationMoeAddFormPopup";
import {usePage} from "@tracabois/utils";
import {OrganizationRef} from "../../../../../referentiels/organizationType";
import {
  RealizationAssignedMoeEvent,
  RealizationAssignMoeCommand,
  Realization
} from "../../../model/realizationModel";
import {usePopup} from "@tracabois/components";
import {useRealizationAssignMoeMutation} from "../../../api";
import {PlatformSubSection} from "@tracabois/template";
import {Action} from "@smartb/g2";

interface RealizationMoeSectionProps {
  realization?: Realization
  title: string
  onMoeAssigned?: (event: RealizationAssignedMoeEvent) => void
  isLoading?: boolean
  editable?: boolean
}

const useLabels = () => {
  const {t} = useTranslation()
  return useMemo(() => ({
    addMoeActor: t("realization.moe.add", "Ajouter un acteur"),
  }), [t])
}

export const RealizationMoeSection = (props: RealizationMoeSectionProps) => {
  const {realization, title, onMoeAssigned, isLoading, editable} = props
  const moePopup = usePopup()
  const labels = useLabels()
  const [assignMoeMutation] = useRealizationAssignMoeMutation()
  const moesPage = usePage<OrganizationRef>(realization?.moes)

  const actions: Action[] = [{
    key: 'moeCreate',
    showIf: () => editable || true,
    onClick: moePopup.open,
    label: labels.addMoeActor
  }]

  const assignMoe = useCallback(async (command: RealizationAssignMoeCommand) => {
    const event = await assignMoeMutation(command)
    // @ts-ignore
    onMoeAssigned && onMoeAssigned(event.data)
    moePopup.close()
    return true
  }, [assignMoeMutation, moePopup, onMoeAssigned])

  return (
    <PlatformSubSection title={title} actions={actions}>
      <RealizationMoeTable page={moesPage.page}
                           isLoading={isLoading}
                           onPageQueryChange={moesPage.setPageQuery}
                           onRowClicked={() => {}}
      />
      {
        editable && realization?.id && moePopup.isOpen && (
          <RealizationMoeAddFormPopup realizationId={realization.id} onClose={moePopup.close} open={moePopup.isOpen}
                                      onSubmit={assignMoe}/>
        )
      }
    </PlatformSubSection>
  )
}

