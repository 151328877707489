import {Typography} from '@mui/material'
import {ConfirmationPopUp} from '@smartb/g2-layout'
import {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'
import {imConfig} from "@tracabois/domain";
import {useUserDisable2} from "@smartb/g2-i2-v2";
import {useExtendedAuth} from "../../../auth";
import {userRoute} from "../../route";

interface DisableUserProps {
  open: boolean
  onClose: () => void
  userId?: string
}

export const DisableUserPopUp = (props: DisableUserProps) => {
  const {open, onClose, userId} = props
  const {t} = useTranslation()
  const {keycloak} = useExtendedAuth()
  const disableUser = useUserDisable2({apiUrl: imConfig.userUrl, jwt: keycloak.token})
  const navigate = useNavigate()
  const onDisableUser = useCallback(
    async () => {
      if (userId) {
        await disableUser.mutateAsync({
          id: userId,
          disabledBy: undefined,
          anonymize: false,
          attributes: undefined
        })
        userRoute.goto.userTable(navigate)
      }
      onClose()
    },
    [disableUser.mutateAsync, userId, onClose, navigate],
  )

  return (
    <ConfirmationPopUp open={open} onClose={onClose} onConfirm={onDisableUser} validateText={t("confirm")}
                       cancelText={t("cancel")}>
      <Typography align="center">{t("user.disableWarning")}</Typography>
    </ConfirmationPopUp>
  )
}
