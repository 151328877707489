import {useMemo} from 'react'
import {
  RemovalZone,
  RemovalZoneCreateCommand,
} from "../../model/removalZone";
import {WoodSpecies} from "../../../../referentiels/woodSpecies";
import {
  TransformationRoles,
  useExtendedAuth,
  useGetOrganizationRefs
} from "@tracabois/connect";
import {DropZonePictureAction} from "../../../brandOrganization";
import {DropZonePicture} from "@smartb/g2-fs";
import {FormComposable, FormComposableState, FormComposableField} from "@smartb/g2-composable";
import {Stack} from '@mui/material'

export interface RemovalZoneFormProps {
  item?: Partial<RemovalZone>
  readonly?: boolean
  woodSpecies: WoodSpecies[],
  formState: FormComposableState
  imageDropZone: DropZonePictureAction
  readonlyOrganization?: boolean
}

export const RemovalZoneForm = (props: RemovalZoneFormProps) => {
  const {
    item,
    readonly = false,
    woodSpecies,
    formState,
    readonlyOrganization = false,
    imageDropZone
  } = props
  const {service} = useExtendedAuth()

  const getOrganizationRefs = useGetOrganizationRefs({
    roles: TransformationRoles
  })

  const detailsFields = useMemo(
    (): FormComposableField[] => [
      {
        key: 'name',
        name: 'name',
        type: 'textField',
        label: 'Nom de la forêt',
        defaultValue: item?.name,
        params: {
          disabled: readonly
        },
        validator: (value?: string) => {
          const trimmed = (value ?? '').trim()
          if (!trimmed) return 'Vous devez renseigner le nom de la forêt' as string
          return undefined
        }
      }, {
        key: 'commune',
        name: 'commune',
        type: 'textField',
        label: 'Commune',
        params: {
          disabled: readonly
        }
      }, {
        key: 'massif',
        name: 'massif',
        type: 'textField',
        label: 'Massif',
        params: {
          disabled: readonly
        }
      },
      {
        key: 'geo.latitude',
        name: 'geo.latitude',
        type: 'textField',
        label: 'Latitude',
        params:{
          placeholder: "43.602829"
        }
      },
      {
        key: 'geo.longitude',
        name: 'geo.longitude',
        type: 'textField',
        label: 'Longitude',
        params:{
          placeholder: "3.879260"
        },
      },
      {
        key: 'organization.id',
        name: 'organization.id',
        type: 'autoComplete',
        label: 'Acteur',
        readonly: readonlyOrganization,
        params: {
          placeholder: 'Sélectionner',
          options: getOrganizationRefs.data.map((item) => ({key: item.id, label: item.name})),
          disabled: !service.isMarque()
        },
        validator: (_: string, value: Partial<RemovalZoneCreateCommand>) => {
          const trimmed = (value.organization?.id ?? '').trim()
          if (!trimmed) return "Vous devez renseigner l'acteur"
          return undefined
        },
      },
    ],
    [readonly, woodSpecies, getOrganizationRefs.data]
  )

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={5}>
        <DropZonePicture {...imageDropZone}/>
        <FormComposable
          fields={detailsFields}
          formState={formState}
        />
      </Stack>
    </Stack>
  )
}
