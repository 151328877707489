import {Grid, Stack} from "@mui/material";
import {DeleteButton} from '@smartb/g2-components'
import {ProductMetricsFieldForm} from "../../ProductMetrics/ProductMetricsField";
import {SourceFields} from "./withSourceFields";
import {FormComposableState} from "@smartb/g2";
import {FormComposable} from "@tracabois/components";

export interface ProductSourceSecondFormLineProps {
  formState: FormComposableState
  sourceFields: SourceFields
  deleteProduct: () => void
}

export const SourceFormLine = (props: ProductSourceSecondFormLineProps) => {
  const {
    formState,
    sourceFields,
    deleteProduct,
  } = props

  return (
    <>
      <Grid container spacing={2} alignItems={"flex-end"}>
        <Grid item xs={6}>
          <FormComposable fields={[sourceFields.providerFields]} formState={formState}/>
        </Grid>
        <Grid item xs={5}/>
        <Grid item xs={1}>
          {!formState.isLoading && !formState.readonly && <DeleteButton onClick={() => deleteProduct()}/>}
        </Grid>
      </Grid>
      <Stack direction="row" gap={2} flexWrap="wrap" alignItems={"flex-end"}>
          <FormComposable sx={{ flexGrow: 1 }} fields={sourceFields.rowProductField} formState={formState}/>
          <ProductMetricsFieldForm field={sourceFields.metricsField} formState={formState}/>
      </Stack>
    </>
  )
}