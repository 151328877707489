import Box from '@mui/material/Box';
import {ForestTicket, OuvrageTicket, ProductTicket, RealizationTicket, WoodTicket} from "@tracabois/components";
import {Stats} from "@tracabois/domain";

export interface IndicatorGridProps {
  indicators?: Stats
  isLoading: boolean
}

export const IndicatorGrid = (props: IndicatorGridProps) => {
  const {indicators, isLoading} = props
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-around" >
        <RealizationTicket indicateur={indicators?.realizations ?? 0} isLoading={isLoading}/>
        <OuvrageTicket indicateur={indicators?.ouvrages ?? 0} isLoading={isLoading}/>
        <ProductTicket indicateur={indicators?.products ?? 0} isLoading={isLoading}/>
        <WoodTicket indicateur={indicators?.wood ?? 0} isLoading={isLoading}/>
        <ForestTicket indicateur={indicators?.forest ?? 0} isLoading={isLoading}/>
        {/*<Co2Ticket indicateur={indicators?.co2 ?? 0} isLoading={isLoading}/>*/}
    </Box>
  )
};
