import {useCallback} from 'react'
import {MoeRoles, useGetOrganizationRefs} from '@tracabois/connect'
import {RealizationAssignMoeCommand, RealizationId} from "../../../model/realizationModel";
import {useFormComposable, useFormFieldComposable} from "@smartb/g2";
import {FormComposable} from "@tracabois/components";

export interface RealizationMoeAssignFormProps {
  onSubmit?: (item: RealizationAssignMoeCommand) => Promise<boolean> | boolean
  onCancel?: () => void
  realizationId: RealizationId
}

export const RealizationMoeAssignForm = (props: RealizationMoeAssignFormProps) => {
  const {onSubmit, onCancel, realizationId} = props

  const organizationList = useGetOrganizationRefs({
    roles: MoeRoles
  }).data || []


 const onSubmitMemoized = useCallback(
    async (values: RealizationAssignMoeCommand) => {
        return !!onSubmit && onSubmit({...values, id: realizationId})
    },
    [onSubmit]
  )

  const form = useFormComposable<RealizationAssignMoeCommand>({
    onSubmit: onSubmitMemoized,
    formikConfig: {
      initialValues: {}
    },
    actions: {
        cancel: {
            label: "Annuler",
            onClick: onCancel,
        },
        validate: {
            label: "Sélectionner",
        }
    }
  })
  const formFields = useFormFieldComposable({
    fields: [
        {
            key: 'organization.id',
            name: 'organization.id',
            type: 'autoComplete',
            validator: (_?: string, value?: Partial<RealizationAssignMoeCommand>) => {
                const trimmed = (value?.organization?.id ?? '').trim()
                if (!trimmed) return 'Vous devez renseigner une organisation' as string
                return undefined
            },
            params: {
                placeholder: 'Sélectionner',
                options: organizationList.map(item => ({
                    key: item.id,
                    label: item.name
                })) || []
            }
        },
    ]
  }, [organizationList])
  return (
      <FormComposable
          fields={formFields.fields}
          formState={form}
          actions={form.actions}
          actionsProps= {{
              direction: 'row',
              justifyContent: 'flex-end',
          }}
      />
  )
}

