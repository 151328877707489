import {Stack} from '@mui/material'
import {BasicProps, useTheme} from '@smartb/g2-themes'
import React from 'react'
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export interface PlatformSectionFilterProps  extends BasicProps {
    children: React.ReactNode;
}

export const PlatformSectionFilter =  (props: PlatformSectionFilterProps) => {
    const { children } = props
    const { colors } = useTheme()
    const sx: SxProps<Theme> = {
        '& .AruiTextfield-root': {
            width: '350px',
        },
        '& .AruiTextfield-root .MuiInputBase-root': {
            backgroundColor: `${colors.primary}12`,
            height: "100%"
        },
        '& .AruiTextfield-root .MuiTextField-root': {
            backgroundColor: `${colors.primary}12`,
            height: "40px"
        },
        '& .AruiTextfield-root .MuiInputBase-root fieldset': {
            border: 0
        },
        padding: '10px 20px'
    }
    return (
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={sx}
      >
          {children}
      </Stack>
    )
}
