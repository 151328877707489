import {Link, LinkProps} from "react-router-dom";
import {useMemo} from "react";
import {MenuItems} from '@smartb/g2-components'
import {useLocation} from "react-router";
import {
  HomeIcon,
  OrganizationIcon,
  OuvrageIcon,
  PersonIcon,
  ProductIcon,
  RealizationIcon,
  SettingsIcon,
  RemovalZoneIcon
} from "@tracabois/components";
import {useExtendedAuth} from "@tracabois/connect";
import {useTranslation} from "react-i18next";
import {AccountCircle, BusinessRounded, LoginRounded, LogoutRounded} from "@mui/icons-material";


export interface MenuEntry {
  key: string,
  to?: string,
  action?: () => void,
  label: string
  icon: JSX.Element;
  isVisible?: boolean;
}

export const getMenu = (location: string, menu: MenuEntry[]): MenuItems<LinkProps>[] => {
  return menu.filter( entry => entry.isVisible === undefined || entry.isVisible).map((item): MenuItems<LinkProps> => {
    const additionals = item.to ? {
      component: Link,
      componentProps: {
        to: item.to
      }
    } : {
      goto: item.action
    }
    return {
      key: `appLayout-${item.key}`,
      label: item.label,
      icon: item.icon,
      isSelected: item.to ? item.to === "/" ? item.to === location : location.includes(item.to) : false,
      ...additionals
    }
  })
}

export const useMenu = () => {
  const location = useLocation()
  const {t} = useTranslation()
  const auth = useExtendedAuth()
  const isFirstTransformationActor = auth.service.isFirstTransformationActor()
  const isMarque = auth.service.isMarque()

  const removalZonesEntry = useMemo( () => {
    return isFirstTransformationActor || isMarque ? [{
      key: "removalZones",
      to: "/removalZones",
      label: "Origines",
      icon: <RemovalZoneIcon/>
    }] : []
  }, [isFirstTransformationActor, isMarque])
  const adminMenu =  useMemo( () => {
    return isMarque ? [{
      key: "organizations",
      to: "/organizations",
      label: t("organization.plurals"),
      icon: <OrganizationIcon/>
    }, {
      key: "users",
      to: "/users",
      label: t("user.plurals"),
      icon: <PersonIcon/>
    }, {
      key: "settings",
      to: "/settings",
      label: "Réglages",
      icon: <SettingsIcon/>
    }] : []
  }, [isMarque])

  const principalMenu = useMemo(() => getMenu(location.pathname, [{
    key: "dashboard",
    to: "/",
    label: t("home"),
    icon: <HomeIcon/>
  }, {
    key: "realizations",
    to: "/realizations",
    label: t("realization.plurals"),
    icon: <RealizationIcon/>
  }, {
    key: "ouvrages",
    to: "/ouvrages",
    label: t("ouvrage.plurals"),
    icon: <OuvrageIcon/>
  }, {
    key: "product",
    to: "/products",
    label: t("product.plurals"),
    icon: <ProductIcon/>
  },
    ...removalZonesEntry,
    ...adminMenu
  ]), [location.pathname, removalZonesEntry, adminMenu])

  return {
    principalMenu: principalMenu,
  }
}


export const useUserMenu = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const {keycloak} = useExtendedAuth()

  const {myProfilRoute, myOrganizationRoute} = useRoutesDefinition()
  const loggedMenu: MenuEntry[] = useMemo(() => [{
    key: "myProfile",
    to: myProfilRoute,
    label: t("user.mine"),
    icon: <AccountCircle/>,
    isVisible: true
  } as MenuEntry, {
    key: "myOrganization",
    to: myOrganizationRoute,
    label: t("organization.mine"),
    icon: <BusinessRounded/>,
    isVisible: true
  } as MenuEntry, {
    key: "logout",
    action: keycloak.logout,
    label: t("logout"),
    icon: <LogoutRounded/>,
    isVisible: true
  } as MenuEntry], [keycloak.logout, t])

  const notLoggedMenu: MenuEntry[] = useMemo(() => [{
    key: "login",
    action: keycloak.login,
    label: t("login"),
    icon: <LoginRounded/>,
    isVisible: true
  }], [keycloak.login, t, myProfilRoute, myOrganizationRoute])

  return {
    loggedMenu: useMemo(() => getMenu(location.pathname, loggedMenu), [location.pathname, loggedMenu]),
    notLoggedMenu: useMemo(() => getMenu(location.pathname, notLoggedMenu), [location.pathname, notLoggedMenu])
  }
}


export const useRoutesDefinition = () => {
  return {
    myProfilRoute: "/myProfile",
    myOrganizationRoute: "/myOrganization",
  }
}
