import {PlatformPage} from "@tracabois/template";
import {Product, ProductTable, useProductsPageQuery} from "@tracabois/domain";
import {Button} from "@smartb/g2-components";
import {Row} from "@smartb/g2-layout";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {productRoute} from "../route";
import {usePageQuery} from "@tracabois/components";
import {useNavigate} from "react-router";

const useLabels = () => {
  const {t} = useTranslation()
  return useMemo(() => ({
    products: t("product.plurals"),
    createProduct: t("product.create"),
  }), [t])
}

export interface ProductListPros {
}

export const ProductListPage = (_: ProductListPros) => {
  const label = useLabels()
  const navigate = useNavigate()
  const productsPageQuery = usePageQuery(useProductsPageQuery);

  const actions = (
    <Button color="primary" onClick={() =>   productRoute.goto.addProduct(navigate)}>{label.createProduct}</Button>
  )

  const clickHandler = (row: Row<Product>) => {
    productRoute.goto.viewProduct(row.original.id, navigate)
  }

  return (
    <PlatformPage title={label.products}>
      <ProductTable
        actions={actions}
        onRowClicked={clickHandler}
        page={productsPageQuery.data}
        isLoading={productsPageQuery.isLoading}
        onPageQueryChange={productsPageQuery.setPageQuery}
      />
    </PlatformPage>
  )
}
