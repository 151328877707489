import React from 'react'
import {makeG2STyles} from "@smartb/g2-themes";
import {Box} from "@mui/system";


const useStyles = makeG2STyles()(
  () => ({
    block: {
      paddingTop: "20px",
      paddingBottom: "40px"
    },
  }))

interface PlatformSectionProps {
  children?: React.ReactNode
}

export const PlatformSection = (props: PlatformSectionProps) => {
  const {classes} = useStyles()
  const {children} = props
  return (<Box className={classes.block} children={children}/>)
}
