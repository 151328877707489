import {AutomatedGallery, AutomatedGalleryFactory} from "@smartb/g2-fs";
import {Stack} from "@mui/material";

type ObjectType = "realization" | "ouvrage"

export interface GalleryProps {
  editable: boolean
  loading: boolean
  name: string,
  type: ObjectType,
  id: string,
}

export const Gallery = (props: GalleryProps) => {
  const {editable, loading, name, type, id} = props
  if (loading) {
    return <></>
  }
  if (editable) {
    return (<AutomatedGalleryFactory
      directoryPath={{
        directory: 'gallery',
        objectType: type,
        objectId: id,
      }}
      galleryName={`La galerie d'image de ${name}`}
      strings={{
        gallery: "",
      }}
      sx={{
        margin: "20px 0",
        height: "200px"
      }}
    />)
  } else {
    return (<Stack sx={{
      margin: "20px 0",
      height: "200px"
    }}>
      <AutomatedGallery
        directoryPath={{
          directory: 'gallery',
          objectType: type,
          objectId: id,
        }}
        galleryName={`La galerie d'image de ${name}`}
        variant="horizontalList"
      />
    </Stack>)
  }
}

