import {useCallback, useEffect, useMemo, useState} from 'react'
import {
  ProductType,
  ProductTypeCreateCommand,
  ProductTypeDeleteCommand,
  ProductTypeUpdateCommand
} from "../../model/ProductType";
import {OrganizationType} from "../../../organizationType";
import {FormComposable, FormComposableField, PopupFormAction} from "@tracabois/components";
import {DropZonePicture, useDropZonePicture} from "@smartb/g2-fs";
import {OuvrageType} from "../../../ouvrageType";
import {useFormComposable} from "@smartb/g2";
import {PlatformSectionForm} from "@tracabois/template/src/platform/PlatformSectionForm";

export interface ProductTypeFormProps {
  item?: Partial<ProductType>
  onCreate: (item: ProductTypeCreateCommand) => Promise<boolean> | boolean
  onUpdate: (item: ProductTypeUpdateCommand) => Promise<boolean> | boolean
  onDelete: (item: ProductTypeDeleteCommand) => Promise<boolean> | boolean
  onCancel: () => void
  readonly?: boolean
  organizationTypes: OrganizationType[]
  ouvrageTypes: OuvrageType[]
}

export const ProductTypeForm = (props: ProductTypeFormProps) => {
  const {
    item,
    onCreate,
    onUpdate,
    onDelete,
    onCancel,
    readonly = false,
    organizationTypes,
    ouvrageTypes
  } = props
  const imageDropZone = useDropZonePicture(item?.img?.url);
  const [feedback, setFeedback] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    setFeedback(undefined)
  }, [onCreate, onUpdate])

  const onSubmitMemoized = useCallback(
    async (values: ProductTypeCreateCommand | ProductTypeUpdateCommand) => {
      if (!item?.id) {

        const feedback = await onCreate({
          ...item,
          ...values,
          // @ts-ignore
          organizationTypes: values?.organizationTypes?.map( it => ({id: it})),
          // @ts-ignore
          ouvrageTypes: values?.ouvrageTypes?.map( it => ({id: it})),
          img: imageDropZone.image
        })
        setFeedback(feedback)
        return feedback
      } else {
        const feedback = await onUpdate({
          ...item,
          ...values,
          img: imageDropZone.image,
          // @ts-ignore
          organizationTypes: values?.organizationTypes?.map( it => ({id: it})),
          // @ts-ignore
          ouvrageTypes: values?.ouvrageTypes?.map( it => ({id: it})),
          id: item.id
        })
        setFeedback(feedback)
        return feedback
      }
    },
    [onCreate, onUpdate, imageDropZone.image, item]
  )

  const details = useMemo(
    (): FormComposableField[] => [
      {
        key: 'name',
        name: 'name',
        type: 'textField',
        label: 'Libellé du type d’opération',
        defaultValue: item?.name,
        params: {
          disabled: readonly
        },
        validator: (value?: string) => {
          const trimmed = (value ?? '').trim()
          if (!trimmed) return 'Vous devez renseigner le nom' as string
          return undefined
        }
      }, {
        key: 'organizationTypes',
        name: 'organizationTypes',
        type: 'autoComplete',
        label: 'Type d’acteurs',
        defaultValue: item?.organizationTypes?.map((it)=> it.id),
        params: {
          disabled: readonly,
          options: organizationTypes.map((it) => ({
            key: it.id,
            label: it.name
          })),
          multiple: true
        }
      }, {
        key: 'ouvrageTypes',
        name: 'ouvrageTypes',
        type: 'autoComplete',
        label: 'Type d’ouvrage',
        defaultValue: item?.ouvrageTypes?.map((it)=> it.id),
        params: {
          disabled: readonly,
          options: ouvrageTypes.map((it) => ({
            key: it.id,
            label: it.name
          })),
          multiple: true
        }
      }
    ],
    [readonly]
  )

  const formState = useFormComposable({
    onSubmit: onSubmitMemoized
  })
  const handleOnDelete = item?.id ? (() => onDelete({id: item.id!!})) : undefined
  return (
    <PlatformSectionForm>
      <DropZonePicture {...imageDropZone}/>
      <FormComposable
        fields={details}
        formState={formState}
      />
      <PopupFormAction
        formState={formState}
        onCancel={onCancel}
        feedback={feedback}
        readonly={readonly}
        isCreation={!item?.id}
        onDelete={handleOnDelete}
      />
    </PlatformSectionForm>
  )
}
