import { Stack, Typography, styled } from "@mui/material";
import {brandConfig} from "@tracabois/domain";

export interface LogoWithTitleProps {
    isMobile?: boolean;
    className?: string;
}

const LogoWithTitleBase = (props: LogoWithTitleProps) => {
    const { isMobile = false, className } = props;
    const brandTheme = brandConfig()
    const withBrandName = brandTheme.certificate?.withBrandName ?? false
    const logo = `/logo_${brandTheme.code}.png`
    return (
        <Stack
            direction="row"
            alignItems="center"
            className={className}
            sx={{
                gap: "10px",
                "& .logo": !isMobile ? {
                    width: "110px",
                    height: "110px",
                } : {
                    width: "70px",
                    height: "70px",
                }
            }}
        >
            <img className='logo' src={logo} alt={brandTheme.title} />
            <Stack>
                {withBrandName &&
                    <Typography sx={{ color: "primary.main" }} variant={isMobile ? "subtitle1" : "h6"}>{brandTheme.title}</Typography>
                }
                <Typography sx={{ color: "primary.main" }} variant={isMobile ? "subtitle1" : "h6"}>Tracé</Typography>
            </Stack>
        </Stack>
    )
}

export const LogoWithTitle = styled(LogoWithTitleBase)({

})
