import {city as evidence} from "tracabois-platform-product-evidence-domain";
import {ProductSection} from "./product";

export type ProductEvidenceUploadCommand = evidence.smartb.tracabois.platform.f2.product.evidence.domain.command.ProductEvidenceUploadCommandDTO
export type ProductEvidenceUploadedEvent = evidence.smartb.tracabois.platform.f2.product.evidence.domain.command.ProductEvidenceUploadedEventDTO

export type ProductEvidenceDeleteCommand = evidence.smartb.tracabois.platform.f2.product.evidence.domain.command.ProductEvidenceDeleteCommandDTO
export type ProductEvidenceDeletedEvent = evidence.smartb.tracabois.platform.f2.product.evidence.domain.command.ProductEvidenceDeletedEventDTO

export type ProductEvidenceListQuery = evidence.smartb.tracabois.platform.f2.product.evidence.domain.query.ProductEvidenceListQueryDTO
export type ProductEvidenceListResult = evidence.smartb.tracabois.platform.f2.product.evidence.domain.query.ProductEvidenceListResultDTO

export type ProductEvidenceFile = evidence.smartb.tracabois.platform.f2.product.evidence.domain.model.ProductEvidenceFileDTO
export type ProductEvidenceSection = evidence.smartb.tracabois.platform.f2.product.evidence.domain.model.ProductEvidenceSectionDTO

export const ProductEvidenceSectionValues: Record<string, ProductEvidenceSection> = {
  from: {value: evidence.smartb.tracabois.platform.f2.product.evidence.domain.model.ProductEvidenceSectionValues.from()},
  to: {value: evidence.smartb.tracabois.platform.f2.product.evidence.domain.model.ProductEvidenceSectionValues.to()}
}
export const toProductEvidenceSection = (section: ProductSection): ProductEvidenceSection => {
  return ProductEvidenceSectionValues[section]
}

export type ProductEvidencePoliciesType = typeof evidence.smartb.tracabois.platform.f2.product.evidence.domain.policy.ProductEvidencePolicies
const policies = evidence.smartb.tracabois.platform.f2.product.evidence.domain.policy.ProductEvidencePolicies
const policiesKeys = Object.keys(Object.getPrototypeOf(policies)) as any[]
export const ProduitEvidencePolicies: ProductEvidencePoliciesType = policiesKeys.reduce<ProductEvidencePoliciesType>((object, key) => {
  //@ts-ignore
  object[key] = policies[key].bind(policies)
  return object
  //@ts-ignore
}, {})

export interface ProduitEvidencePoliciesResult {
  canUpload: boolean
  canDelete: boolean
  canDownload: boolean
  canList: boolean
}
