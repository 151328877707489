import {FormComposableState, FormState} from "@smartb/g2";
import {getIn, setIn} from "formik";

// FROM https://github.com/jaredpalmer/formik/blob/master/packages/formik/src/FieldArray.tsx
export const remove = (index: number): Function => (array?: any[]) => {
  const copy = array ? copyArrayLike(array) : [];
  copy.splice && copy.splice(index, 1);
  return copy;
}

export const insert = () => (
  arrayLike: ArrayLike<any>,
) => {
  const copy = copyArrayLike(arrayLike);
  copy.splice(copy.length, 0, {});
  return copy;
};

export const updateArrayField = (
  fn: Function,
  name: string,
  alterTouched: boolean | Function,
  alterErrors: boolean | Function,
  formState: FormState
) => {
  formState.setFormikState((prevState) => {
    let updateErrors = typeof alterErrors === 'function' ? alterErrors : fn;
    let updateTouched =
      typeof alterTouched === 'function' ? alterTouched : fn;

    // values fn should be executed before updateErrors and updateTouched,
    // otherwise it causes an error with unshift.
    const fieldValue = getIn(prevState.values, name)
    let values = setIn(
      prevState.values,
      name,
      fn(fieldValue)
    );

    let fieldError = alterErrors
      ? updateErrors(getIn(prevState.errors, name))
      : undefined;
    let fieldTouched = alterTouched
      ? updateTouched(getIn(prevState.touched, name))
      : undefined;

    if (!!fieldError || fieldError.length == 0) {
      fieldError = undefined;
    }
    if (!!fieldTouched || fieldTouched.length == 0) {
      fieldTouched = undefined;
    }

    return {
      ...prevState,
      values,
      errors: alterErrors
        ? setIn(prevState.errors, name, fieldError)
        : prevState.errors,
      touched: alterTouched
        ? setIn(prevState.touched, name, fieldTouched)
        : prevState.touched,
    };
  });
};

const copyArrayLike = (arrayLike: ArrayLike<any>) => {
  if (!arrayLike) {
    return [];
  } else if (Array.isArray(arrayLike)) {
    return [...arrayLike];
  } else {
    const maxIndex = Object.keys(arrayLike)
      .map(key => parseInt(key))
      .reduce((max, el) => (el > max ? el : max), 0);
    return Array.from({ ...arrayLike, length: maxIndex + 1 });
  }
};

const addLine = (formState: FormComposableState, fieldName: string) => {
  FormComposableArray.updateArrayField(
    FormComposableArray.insert(),
    fieldName,
    true,
    true,
    formState
  )
}

const deleteLine = (formState: FormComposableState, fieldName: string, index: number) => {
  FormComposableArray.updateArrayField(
    FormComposableArray.remove(index),
    fieldName,
    true,
    true,
    formState
  )
}

export const FormComposableArray = {
  updateArrayField: updateArrayField,
  remove: remove,
  insert: insert,
  addLine: addLine,
  deleteLine: deleteLine
}

