import {OuvrageRef, useGetOuvragesRefsQuery} from "../../../../ouvrage";
import {RemovalZone, useRemovalZonePageQuery} from "../../../../removalZone";
import {ProductType, useProductTypeAllQuery} from "../../../../../referentiels/productType";
import {Unit} from "../../../../../domain/unit";
import {useWoodSpeciesAllQuery, WoodSpecies} from "../../../../../referentiels/woodSpecies";
import {allPage} from "@tracabois/utils";
import {MoeRoles, FirstTransformationRoles, useGetOrganizationRefs, TransformationRoles} from "@tracabois/connect";
import {OrganizationRef} from "@smartb/g2-i2";


export interface DataSelection {
  removalZonePageRefetch: () => void
  transformationActor?: OrganizationRef
  ouvrages: OuvrageRef[]
  transformationOrganizations: OrganizationRef[]
  firstTransformationOrganizations: OrganizationRef[]
  moeOrganizations: OrganizationRef[]
  removalZones: RemovalZone[]
  rowProductTypes: ProductType[]
  endProductTypes: ProductType[]
  units: Unit[]
  woodSpecies: WoodSpecies[]
  isLoading: boolean
}

export const useDataSelection = (transformationActor?: OrganizationRef): DataSelection => {
  const units: Unit[] = ["m3"]
  const useRowProductsTypePageQuery = useProductTypeAllQuery()
  const useEndProductsTypePageQuery = useRowProductsTypePageQuery
  const useWoodSpeciesQuery = useWoodSpeciesAllQuery()
  const removalZonePageQuery = useRemovalZonePageQuery({
    pagination: allPage,
    search: undefined,
    filters: {
      organizationId: transformationActor?.id
    }
  })
  const ouvragesPageQuery = useGetOuvragesRefsQuery(undefined)
  const transformationOrganizations = useGetOrganizationRefs({
    roles: TransformationRoles
  }).data
  const firstTransformationOrganizations = useGetOrganizationRefs({
    roles: FirstTransformationRoles
  }).data
  const moeOrganizations = useGetOrganizationRefs({
    roles: MoeRoles
  }).data
  const removalZone = transformationActor ? removalZonePageQuery.data?.items || [] : []
  return {
    transformationActor: transformationActor,
    removalZonePageRefetch: removalZonePageQuery.refetch,
    removalZones: removalZone,
    rowProductTypes: useRowProductsTypePageQuery.data?.items || [],
    units: units,
    woodSpecies: useWoodSpeciesQuery.getAll() || [],
    ouvrages: ouvragesPageQuery.data || [],
    transformationOrganizations: transformationOrganizations,
    firstTransformationOrganizations: firstTransformationOrganizations,
    moeOrganizations: moeOrganizations,
    endProductTypes: useEndProductsTypePageQuery.data?.items || [],
    isLoading: removalZonePageQuery.isLoading &&
      useRowProductsTypePageQuery.isLoading &&
      useWoodSpeciesQuery.isLoading &&
      ouvragesPageQuery.isLoading &&
      useEndProductsTypePageQuery.isLoading
  };
}
