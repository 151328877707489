import {Stack} from '@mui/material'
import {BasicProps} from '@smartb/g2-themes'
import React from 'react'

export interface PlatformSectionFormProps  extends BasicProps {
    children: React.ReactNode;
}

export const PlatformSectionForm =  (props: PlatformSectionFormProps) => {
    const {children} = props
    return (
        <Stack gap={2} >
            {children}
        </Stack>
    )
}
