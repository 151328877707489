import { FilterComposableField } from '@smartb/g2'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {useFilters} from "@tracabois/components";

export const useOrganizationFilters = () => {
    const {t} = useTranslation()

    const filters = useMemo((): FilterComposableField[] => [
        {
            key: 'organizationSearchFilter',
            name: 'search',
            type: 'textField',
            params: { textFieldType: 'search', placeholder: t("organizationList.nameFilter"), style: { minWidth: "220px" } },
            mandatory: true
        }
    ], [t])
    return useFilters({filters: filters})
}
