import {DependencyList, Dispatch, SetStateAction, useCallback, useState} from "react"
import {Button} from '@smartb/g2-components'
import {Stack} from "@mui/material";

export type ValidationButtonResult = [React.ReactElement, boolean | undefined, Dispatch<SetStateAction<boolean | undefined>>]
export const useValidationButton = (submitChanges: () => boolean | Promise<boolean>, deps: DependencyList): ValidationButtonResult => {
  const [feedback, setFeedback] = useState<boolean | undefined>(undefined)
  const handleActionWithFeedBack = useCallback(async () => {
    const result = await submitChanges()
    setFeedback(result)
    setTimeout(() => {
      setFeedback(undefined)
    }, 1500);
  }, deps)
  const actions = (
    <Stack>
      <Button
        success={feedback !== undefined && feedback}
        fail={feedback !== undefined && !feedback}
        onClick={handleActionWithFeedBack}>
        Valider
      </Button>
    </Stack>
  )
  return [actions, feedback, setFeedback]
}
