import {useCallback, useEffect, useMemo, useState} from 'react'
import {FormAction} from '@smartb/g2-forms'
import {Stack} from '@mui/material'
import {useOrganizationTypePageQuery} from "../../../../../referentiels/organizationType";
import {RealizationAssignMoeCommand, RealizationId} from "../../../model/realizationModel";
import {firstPage} from "@tracabois/utils";
import {useFormComposable} from "@smartb/g2";
import {FormComposable, FormComposableField} from "@tracabois/components";

export interface RealizationMoeCreateFormProps {
  onSubmit?: (item: RealizationAssignMoeCommand) => Promise<boolean> | boolean
  onCancel?: () => void
  realizationId: RealizationId
}

export const RealizationMoeCreateForm = (props: RealizationMoeCreateFormProps) => {
  const {
    onSubmit,
    onCancel,
    realizationId
  } = props
  const {data} = useOrganizationTypePageQuery({
    pagination: firstPage
  })

  const [feedback, setFeedback] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    setFeedback(undefined)
  }, [onSubmit])

  const onSubmitMemoized = useCallback(
    async (values: RealizationAssignMoeCommand) => {
      if (!onSubmit) {
        return false
      }
      const feedback = await onSubmit({...values, id: realizationId})
      setFeedback(feedback)
      return feedback
    },
    [onSubmit]
  )


  const formState = useFormComposable({
    onSubmit: onSubmitMemoized
  })

  const details = useMemo(
    (): FormComposableField[] => [
      {
        key: 'organizationName',
        name: 'organizationName',
        type: 'textField',
        label: 'Raison sociale',
        params: {
          placeholder: 'Raison sociale'
        },
        validator: (value?: string) => {
          const trimmed = (value ?? '').trim()
          if (!trimmed) return 'Vous devez renseigner le nom' as string
          return undefined
        }
      }, {
        key: 'siret',
        name: 'siret',
        type: 'textField',
        label: 'Siret',
        params: {
          placeholder: 'Siret'
        }
      }, {
        key: 'organizationType.id',
        name: 'organizationType.id',
        type: 'autoComplete',
        label: 'Type de donneur d’ordre',
        validator: (value?: string) => {
          const trimmed = (value ?? '').trim()
          if (!trimmed) return "Vous devez renseigner le type de l'organisation" as string
          return undefined
        },
        params: {
          placeholder: 'Raison sociale',
          options: data?.items?.map((item) => ({key: item.id, label: item.name}))
        },
      },
    ],
    [data?.items]
  )

  const actions: FormAction[] = [{
    key: "cancel",
    label: "Annuler",
    onClick: onCancel,
    variant: "text"
  },{
    key: "create",
    label: "Créer",
    success: (feedback !== undefined && feedback),
    fail: (feedback !== undefined && !feedback),
    onClick: formState.submitForm
  }]

  return (
    <Stack
      alignItems='center'
      width='100%'
      spacing={2}
    >
      <Stack
        direction='row'
        flexWrap='wrap'
        justifyContent='space-between'
        width='100%'
      >
        <FormComposable
          fields={details}
          formState={formState}
          actions={actions}
          style={{width:'100%'}}
          actionsProps= {{
            direction: 'row',
            justifyContent: 'flex-end',
            width:'100%',
            spacing: 2
          }}
        />

      </Stack>
    </Stack>
  )
}
