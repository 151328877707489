import {useState} from "react";
import {WoodSpeciesFormPopup} from "../form/WoodSpeciesFormPopup";
import {WoodSpeciesTable} from "../table/WoodSpeciesTable";
import {Button} from '@smartb/g2-components'
import {useTranslation} from "react-i18next";
import {Row} from "@smartb/g2-layout";
import {
  WoodSpecies,
  WoodSpeciesCreateCommand,
  WoodSpeciesDeleteCommand,
  WoodSpeciesUpdateCommand
} from "../../model/WoodSpecies";
import {
  useWoodSpeciesAllQuery,
  useWoodSpeciesCreateMutation, useWoodSpeciesDeleteMutation,
  useWoodSpeciesUpdateMutation
} from "../../api/WoodSpeciesApi";
import {PlatformSubSection} from "@tracabois/template";

interface WoodSpeciesSectionProps {
  title: string
}

export const WoodSpeciesSection = (props: WoodSpeciesSectionProps) => {
  const {title} = props
  const [selectedItem, setSelectedItem] = useState<Partial<WoodSpecies> | undefined>(undefined)

  const {t} = useTranslation()
  const woodSpeciesPageQuery = useWoodSpeciesAllQuery();
  const [createWoodSpecies] = useWoodSpeciesCreateMutation()
  const [updateWoodSpecies] = useWoodSpeciesUpdateMutation()
  const [deleteWoodSpecies] = useWoodSpeciesDeleteMutation()

  const closePopup = () => {
    setSelectedItem(undefined)
  }

  const handleCreate = async (item: WoodSpeciesCreateCommand) => {
    await createWoodSpecies(item)
    closePopup()
    woodSpeciesPageQuery.refetch()
    return true
  }
  const handleUpdate = async (item: WoodSpeciesUpdateCommand) => {
    await updateWoodSpecies(item)
    closePopup()
    woodSpeciesPageQuery.refetch()
    return true
  }
  const handleDelete = async (item: WoodSpeciesDeleteCommand) => {
    await deleteWoodSpecies(item)
    closePopup()
    woodSpeciesPageQuery.refetch()
    return true
  }
  const clickHandler = (wood: Row<WoodSpecies>) => {
    setSelectedItem(wood.original)
  }

  const actions = (<Button color="primary" onClick={() => setSelectedItem({})} >{t("woodSpecies.action.create", "Ajouter une essence")}</Button>)

  return (
    <PlatformSubSection title={title}>
      <WoodSpeciesTable
        page={woodSpeciesPageQuery.data}
        onPageQueryChange={woodSpeciesPageQuery.setPageQuery}
        onRowClicked={clickHandler}
        isLoading={woodSpeciesPageQuery.isLoading}
        actions={actions}
      />
      <WoodSpeciesFormPopup
        open={!!selectedItem} item={selectedItem || {}}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        onCancel={closePopup}
      />
    </PlatformSubSection>
  )
}
