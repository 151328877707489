import {useState} from "react";
import {RemovalZoneTable} from "../table/RemovalZoneTable";
import {usePageQuery} from "@tracabois/components";
import {useTranslation} from "react-i18next";
import {Row} from "@smartb/g2-layout";
import {useRemovalZonePageQuery} from "../../api/RemovalZoneApi";
import {RemovalZone, RemovalZoneCreatedEvent, RemovalZoneId} from "../../model/removalZone";
import {RemovalZoneFormCreatePopupButton} from "../form/RemovalZoneFormCreatePopupButton";
import {useExtendedAuth} from "@tracabois/connect";

interface RemovalZoneSectionProps {
  noElevation?: boolean
  onRowClick: (item: RemovalZoneId) => void
  onRemovalZoneCreated: (item: RemovalZoneId) => void
}

export const RemovalZoneListSection = (props: RemovalZoneSectionProps) => {
  const {noElevation, onRowClick, onRemovalZoneCreated} = props
  const [selectedItem, setSelectedItem] = useState<Partial<RemovalZone> | undefined>(undefined)
  const {t} = useTranslation()
  const {service} = useExtendedAuth()
  const memberOf =  service.getMemberOf()
  const orgId: string | undefined = (memberOf && !service.isMarque()) ? memberOf : undefined

  const removalZonePageQuery = usePageQuery(useRemovalZonePageQuery);
  const clickHandler = (item: Row<RemovalZone>) => {
    onRowClick(item.original.id)
  }
  const createdHandler = (item: RemovalZoneCreatedEvent) => {
    onRemovalZoneCreated(item.id)
  }
  const onClose = () => {
    setSelectedItem(undefined)
  }
  const actions = (<RemovalZoneFormCreatePopupButton
    item={selectedItem}
    label={t("removalZone.action.create", "Ajouter une zone")}
    onRemovalZoneCreated={createdHandler}
    onClose={onClose}
    actor={orgId}
  />)
  return (<>
    <RemovalZoneTable page={removalZonePageQuery.data}
                      onPageQueryChange={removalZonePageQuery.setPageQuery}
                      onRowClicked={clickHandler}
                      isLoading={removalZonePageQuery.isLoading}
                      actions={actions}
                      noElevation={noElevation}
    />
  </>)
}
