import {EditButton as G2EditButton} from '@smartb/g2-components';

export interface EditButtonProps {
  onClick: () => void;
}

export const EditButton = (props: EditButtonProps) => {
  const {onClick} = props
  return (
    <G2EditButton style={{margin:0}} onClick={onClick} variant="text"/>
  )
};


