import {useCallback, useEffect, useMemo} from "react";
import {EndProductModel, ProductModel, ProductUpdateCommand} from "../../../model/product";
import {useProductUpdateMutation} from "../../../api";
import {useDataSelection} from "./useDataSelection";
import {DestinationForm} from "./DestinationForm/DestinationForm";
import {useProviderFields} from "./ProviderForm/useProviderFields";
import {ProviderFormSection} from "./ProviderForm/ProviderFormSection";
import {OrganizationRef} from "@tracabois/web-certificate/src/App/model";
import {FormComposableState, useFormComposable} from "@smartb/g2";
import {ProductEvidenceUpload} from "../ProductEvidenceUpload/ProductEvidenceUpload";
import {Stack} from "@mui/material";
import {useProductEvidenceListFunction} from "../../../api/useProductEvidenceListFunction";

export interface ProductFormProps {
  product?: ProductModel
  transformer?: OrganizationRef
  readOnly: boolean
  loading: boolean
  onFormReady: (formState: FormComposableState) => void
}


export const ProductForm = (props: ProductFormProps) => {
  const {product, onFormReady, transformer, readOnly, loading} = props

  const [productUpdate] = useProductUpdateMutation()

  const handleFormSubmit = useCallback(
    async (values: ProductUpdateCommand) => {
      const model: ProductUpdateCommand = {
        id: product?.id || "",
        // @ts-ignore
        source: values.source,
        ouvrage: values.ouvrage ? { id: values.ouvrage.id} : undefined,
        // @ts-ignore
        destination: {
          ...values.destination,
          // @ts-ignore
          endProduct: endProductModelToApi(values.destination.endProduct)
        },
        origin: values.origin,
      }
      await productUpdate(model)
      return true
    },
    [product?.id]
  )

  const initialValues = useMemo( () => {
    const source = product?.source ? product?.source : {row: [{}]}
    return {
      ...product,
      source: source,
      destination: {
        ...product?.destination,
        endProduct: endProductModelToForm(product?.destination?.endProduct || [])
      },
    }
  }, [product])

  const formState = useFormComposable<ProductModel>({
    onSubmit: handleFormSubmit,
    isLoading: loading,
    readonly: readOnly,
    formikConfig: {
      initialValues: initialValues,
      // enableReinitialize: true
    }
  })

  const dataSelection = useDataSelection(formState.values?.transformation?.actor)
  const providerFields = useProviderFields({formState: formState, dataSelection: dataSelection, transformer: transformer})
  const productEvidenceList = useProductEvidenceListFunction({
    query: {
      id: product?.id ?? ""
    },
    options: {
      enabled: !!product
    }
  })


  useEffect(() => {
    onFormReady(formState)
  }, [])
  const onProductEvidenceUploaded = useCallback( async () => {
    await productEvidenceList.refetch()
  },[productEvidenceList])
  return (<>
    <Stack>
      <DestinationForm
        formState={formState}
        dataSelection={dataSelection}
        transformer={transformer}
      />
      {product && <ProductEvidenceUpload
          section="to"
          loading={loading || productEvidenceList.isLoading}
          readOnly={readOnly}
          evidences={productEvidenceList.data?.to}
          onProductEvidenceUploaded={onProductEvidenceUploaded}
          product={product}
      />}
    </Stack>
    <Stack>
      <ProviderFormSection
        formState={formState}
        transformer={transformer}
        fields={providerFields}
      />
      {product && <ProductEvidenceUpload
          section="from"
          loading={loading || productEvidenceList.isLoading}
          readOnly={readOnly}
          evidences={productEvidenceList.data?.from}
          product={product}
          onProductEvidenceUploaded={onProductEvidenceUploaded}
      />}
    </Stack>
  </>)
}

const endProductModelToForm =  (endProducts: Array<EndProductModel>): Array<EndProductModel> => {
  return endProducts?.map((endProduct: EndProductModel): EndProductModel => {
    return {
      ...endProduct,
      // @ts-ignore
      productTypes: objIdsToIds(endProduct.productTypes),
      metrics: {
        ...endProduct.metrics,
        // @ts-ignore
        species: objIdsToIds(endProduct?.metrics?.species),
      }
    }
  })
}

const endProductModelToApi =  (endProducts: Array<EndProductModel>): Array<EndProductModel> => {
  return endProducts?.map((endProduct: EndProductModel): EndProductModel => {
    return {
      ...endProduct,
      // @ts-ignore
      productTypes: idsToObjIds(endProduct?.productTypes),
      // @ts-ignore
      metrics: {
        ...endProduct?.metrics,
        // @ts-ignore
        species: idsToObjIds(endProduct?.metrics?.species),
      }
    }
  })
}

const idsToObjIds =  (ids: Array<string> = []): Array<{id: string}> => ids.map(id => ({id: id}))
const objIdsToIds =  (ids: Array<{id: string}> = []): Array<string> => {
  return ids.map(obj => obj.id)
}
