import { Stack } from "@mui/material";
import { DeleteButton } from '@smartb/g2-components'
import { ProductMetricsFieldForm } from "../../ProductMetrics/ProductMetricsField";
import { OriginFields } from "./withOriginFields";
import { FormComposableState } from "@smartb/g2";
import { FormComposable } from "@tracabois/components";

export interface OriginZoneFormProps {
  formState: FormComposableState
  fields: OriginFields
  deleteProduct: () => void
}

export const OriginZoneForm = (props: OriginZoneFormProps) => {
  const {
    formState,
    fields,
    deleteProduct,
  } = props

  return (
    <Stack direction="row" gap={2} flexWrap="wrap" alignItems={"flex-end"}>
      <FormComposable sx={{ flexGrow: 1 }} fields={[fields.origin]} formState={formState} />
      <ProductMetricsFieldForm field={fields.metricsField} formState={formState} />
      {!formState.isLoading && !formState.readonly && <DeleteButton onClick={() => deleteProduct()} />}
    </Stack>
  )
}