import {useOrganizationFormState} from "@smartb/g2-i2-v2";
import {useParams} from "react-router";
import {PlatformPage} from "@tracabois/template";
import {
  useGetBtmcOrganizationByIdQuery,
  useBtmcOrganizationAssignBtmcNumberMutation, DropZonePictureAction
} from "@tracabois/domain";
import {useTranslation} from "react-i18next";
import {Action, useActionFeedback, useFormComposable} from "@smartb/g2";
import {useCallback, useState} from "react";
import {BtmcOrganizationAssignBtmcNumberCommand} from "@tracabois/domain/src/s2/brandOrganization/model";
import {useDropZonePicture} from "@smartb/g2-fs";
import {organizationRoles} from "../../../auth";
import {DisableOrganizationPopup} from "../../components/DisableOrganizationPopup";
import {OrganizationForm} from "../../components/OrganizationForm";

export interface OrganizationEditPageProps {
  title?: string
  update?: boolean
  readonly?: boolean
  organizationId?: string
}

export const OrganizationEditPage =(props: OrganizationEditPageProps) => {
  const {
    title,
  } = props;
  const { organizationId = props.organizationId!!} = useParams<{ organizationId: string }>();
  const {t} = useTranslation()
  const feedback = useActionFeedback()
  const [openDisablePopup, setOpenDisablePopup] = useState(false)

  const btmcOrganizationQuery = useGetBtmcOrganizationByIdQuery({id: organizationId})
  const [assignBtmcNumber] = useBtmcOrganizationAssignBtmcNumberMutation()
  const imageDropZone: DropZonePictureAction = useDropZonePicture(btmcOrganizationQuery.data?.img?.url)

  const onSubmit = useCallback(
    async (values: Partial<BtmcOrganizationAssignBtmcNumberCommand>) => {
      await assignBtmcNumber({
        id: organizationId,
        btmcNumber: values.btmcNumber || "",
        img: imageDropZone.image
      })
      return true
    },
    [assignBtmcNumber, imageDropZone.image]
  )
  const btmcFormState = useFormComposable<BtmcOrganizationAssignBtmcNumberCommand>({
    onSubmit: onSubmit,
    formikConfig: {
      initialValues: btmcOrganizationQuery.data
    },
  })

  const organizationFormState = useOrganizationFormState({
    update: true,
    organizationId: organizationId,
    defaultRoles: [...organizationRoles],
    multipleRoles: true,
  })

  const actions: Action[] = [{
    key: "save",
    label: t("save"),
    onClick: async (): Promise<boolean> => {
      await Promise.all([
        organizationFormState.formState.submitForm(),
        btmcFormState.submitForm()
      ])
      feedback.setFeedback(true)
      return true
    },
    ...feedback
  }, {
    key: "disable",
    label: t("disable"),
    onClick: () => {
      setOpenDisablePopup(true)
    },
    variant: 'deletion'
  }]
  return (
    <PlatformPage title={title ?? t("organization.one")} actions={actions}>
      <OrganizationForm
        organizationId={organizationId} update
        formState={organizationFormState.formState}
        organization={organizationFormState.organization}
        isLoading={organizationFormState.isLoading}
        btmcForm={{
          formState: btmcFormState,
          organization: btmcOrganizationQuery.data,
          imageDropZone: imageDropZone
        }}
        refreshData={btmcOrganizationQuery.refetch}
      />
      <DisableOrganizationPopup organizationId={organizationId} open={openDisablePopup} onClose={() => setOpenDisablePopup(false)} />
    </PlatformPage>
  );
};
