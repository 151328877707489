import {Page as AruiPage, Action, ActionsWrapper} from '@smartb/g2'
import React from "react";
import {PageHeaderTitle} from "../PlatformPageHeaderTitle";

interface PlatformPageProps {
  children?: React.ReactNode
  title?: string
  actions?: Action[]
  isLoading?: boolean
}

export const PlatformPage = (props: PlatformPageProps) => {
  const {children, title, actions, isLoading} = props
  const headerProps = {
    content: [{
      leftPart: [
        <PageHeaderTitle key="PlatformPage-PageHeaderTitle-1" title={title} isLoading={isLoading} />
      ],
      rightPart: [
        <ActionsWrapper key="PlatformPage-ActionsWrapper-1" actions={actions} />
      ]
    }],
  }
  return (
    <AruiPage headerProps={headerProps}>
      {children}
    </AruiPage>
  )
}
