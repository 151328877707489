import {PlatformPage} from "@tracabois/template";
import {
  OuvrageTable, Ouvrage, OuvrageCreateCommand,
  useGetOuvragesPageQuery,
  useOuvrageCreateMutation,
  OuvrageCreateFormPopup,
} from "@tracabois/domain";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {Button} from "@smartb/g2-components";
import {Row} from "@smartb/g2-layout";
import {ouvrageRoute} from "../route";
import {usePageQuery, usePopup} from "@tracabois/components";
import {useNavigate} from "react-router";

const useLabels = () => {
  const {t} = useTranslation()
  return useMemo(() => ({
    ouvrages: t("ouvrage.plurals"),
    createOuvrage: t("ouvrage.create"),
  }), [t])
}

export interface OuvrageListPros {
}

export const OuvrageListPage = (_: OuvrageListPros) => {
  const popup = usePopup()
  const label = useLabels()
  const navigate = useNavigate()
  const ouvragesPageQuery = usePageQuery(useGetOuvragesPageQuery);
  const [ouvrageCreate] = useOuvrageCreateMutation()

  const handleCreate = async (item: OuvrageCreateCommand) => {
    const result = await ouvrageCreate(item)
    // @ts-ignore
    ouvrageRoute.goto.viewOuvrage(result.data.id, navigate)
    return true
  }

  const actions = (<Button color="primary" onClick={popup.open}>{label.createOuvrage}</Button>)

  const clickHandler = (row: Row<Ouvrage>) => {
    ouvrageRoute.goto.viewOuvrage(row.original.id, navigate)
  }

  return (
    <PlatformPage title={label.ouvrages}>
      <OuvrageTable
        actions={actions}
        onRowClicked={clickHandler}
        page={ouvragesPageQuery.data}
        isLoading={ouvragesPageQuery.isLoading}
        onPageQueryChange={ouvragesPageQuery.setPageQuery}
      />
      <OuvrageCreateFormPopup onSubmit={handleCreate} onClose={popup.close} open={popup.isOpen} />
    </PlatformPage>
  );
}
