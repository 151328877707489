import {PlatformPageObject, PlatformSubSection} from "@tracabois/template";
import {Grid} from "@mui/material";
import {
  useRemovalZoneDeleteMutation,
  useRemovalZoneGetQuery,
} from "@tracabois/domain";
import {useNavigate, useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {useCallback, useEffect, useMemo, useState} from "react";
import {ObjectHeader} from "@tracabois/components";
import {RemovalZoneMapSection, RemovalZoneFormSection} from "@tracabois/domain";
import {DeleteButton} from "@tracabois/components";
import {goto} from "../route/removalZones.goto";
import {FeatureCollection} from "geojson";
import {Action, ActionsWrapper, FormComposableState, useActionFeedback} from "@smartb/g2";

const useLabels = () => {
  const {t} = useTranslation()
  return useMemo(() => ({
    save: t("save"),
    indicators: t("realization.indicators", "Indicateurs"),
  }), [t])
}

type RemovalZoneIdUrl = {
  removalZoneId: string
}

export const RemovalZoneViewPage = () => {
  const {removalZoneId = ""} = useParams<RemovalZoneIdUrl>();
  const navigate = useNavigate()
  const labels = useLabels()
  const [formState, setFormState] = useState<FormComposableState | undefined>(undefined)
  const feedback = useActionFeedback()
  const {data} = useRemovalZoneGetQuery(removalZoneId)
  const [removalZoneDelete] = useRemovalZoneDeleteMutation()
  const [geoJson, setGeoJson] = useState<FeatureCollection<any, any> | undefined>()

  const deleteAction = useCallback(async (): Promise<boolean> => {
    await removalZoneDelete({
      id: removalZoneId
    })
    goto.listRemovalZone(navigate)
    return true;
  }, [navigate, removalZoneId, removalZoneDelete])

  useEffect(() => {
    setGeoJson(data?.item?.geoJson)
  }, [data?.item?.geoJson])
  const handleGeoJsonChange = (geoJson: FeatureCollection<any, any>) => {
    setGeoJson(geoJson)
  }
  const handleSave = useCallback(async () => {
    await formState?.submitForm()
    feedback.setFeedback(true)
  }, [formState])
  const actions: Action[] = [{
    key: "save",
    label: labels.save,
    onClick: handleSave,
    success: feedback.success,
    fail: feedback.fail
  }]
  return (
    <PlatformPageObject>
      <ObjectHeader
        title={data?.item?.name || ""}
        actions={<>
        <ActionsWrapper actions={actions}/>
          <DeleteButton onClick={deleteAction} />
        </>}
      />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <PlatformSubSection title={""}>
            <RemovalZoneMapSection
              geoJson={geoJson}
              onGeoJsonChange={handleGeoJsonChange}
            />
          </PlatformSubSection>
        </Grid>
        <Grid item xs={8}>
          <RemovalZoneFormSection
            title={labels.indicators}
            geoJson={geoJson}
            item={data?.item || undefined}
            onFormStateReady={setFormState} />
        </Grid>
      </Grid>
    </PlatformPageObject>
  );
}
