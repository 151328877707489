import React, {useCallback} from "react";
import {Button} from "@smartb/g2-components";
import {QRCodeIcon} from "../icons";
import {PlatformPageObjectHeader, PlatformPageObjectHeaderAction, PlatformPageObjectHeaderTitle} from "@tracabois/template";
import {useTheme} from "@smartb/g2-themes";

interface ObjectHeaderProps {
  title?: string
  editableTitle?: boolean
  onTitleChange?: (name: string) => void
  onTitleSave?: (name: string) => boolean | Promise<boolean>
  qrcode?: string
  actions?: React.ReactElement
}

export const ObjectHeader = (props: ObjectHeaderProps) => {
  const {title, editableTitle = false, onTitleSave, qrcode, onTitleChange, actions} = props
  return (
    <PlatformPageObjectHeader>
      <PlatformPageObjectHeaderTitle
        title={title}
        editableTitle={editableTitle}
        onTitleSave={onTitleSave}
        onTitleChange={onTitleChange}
      />
      <ObjectHeaderAction qrcode={qrcode} actions={actions}/>
    </PlatformPageObjectHeader>

  )
}

export interface ObjectHeaderActionProps {
  qrcode?: string
  actions?: React.ReactNode
}

export const ObjectHeaderAction = (props: ObjectHeaderActionProps) => {
  const {qrcode, actions} = props
  const theme = useTheme()
  const goToCertificate = useCallback(() => {
    window.open(qrcode, '_blank');
  }, [qrcode])
  return (
    <PlatformPageObjectHeaderAction>
      {actions && actions}
      {qrcode && <Button variant="text" onClick={goToCertificate}><QRCodeIcon color={theme.colors.primary}/></Button>}
    </PlatformPageObjectHeaderAction>
  )
}
