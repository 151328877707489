import {CellProps, Column, Row} from '@smartb/g2-layout'
import {Avatar, Typography} from '@mui/material'
import {TFunction} from 'react-i18next'
import {PlatformTableSearchFilters, PlatformPaginatedTable} from "@tracabois/template";
import {firstPage, OffsetPagination, PageQuery, PageResult} from "@tracabois/utils";
import {ProductType} from "../../model/ProductType";

interface ProductTypeProps {
  page?: PageResult<ProductType>
  isLoading?: boolean
  onPageQueryChange: (page: PageQuery) => void;
  onRowClicked: (row: Row<ProductType>) => void;
  actions: React.ReactNode
}

export const ProductTypeTable = (props: ProductTypeProps) => {
  const {page, isLoading, onPageQueryChange, onRowClicked, actions} = props

  const onSearch = (search?: string) => {
    onPageQueryChange({
      pagination: page?.pagination || firstPage,
      search: search
    })
  }
  const filter = (
    <PlatformTableSearchFilters actions={actions} onSubmit={(props) => onSearch(props.search)}/>
  )
  const onPaginationChange = (pagination: OffsetPagination) => {
    onPageQueryChange({
      pagination: pagination,
      search: page?.search
    })
  }
  return (
    <PlatformPaginatedTable<ProductType>
      page={page}
      columnsDefinition={getColumns}
      isLoading={isLoading}
      actions={filter}
      onPageChange={onPaginationChange}
      onRowClicked={onRowClicked}
      noElevation
    />
  )
}

// @ts-ignore
const getColumns = (t: TFunction): Column<ProductType>[] => ([{
  Header: `${t("productType.img", "Image")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<ProductType>) => (
    <Avatar alt={row.original.name} src={row.original.img?.url || ""} variant="square"/>
  )
}, {
  Header: `${t("productType.name", "Libéllé")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<ProductType>) => (
    <Typography variant="body2"
    >{row.original.name}</Typography>
  )
}, {
  Header: `${t("productType.organizationType", "Type d'acteurs")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<ProductType>) => {
    return (
      <>{
        row.original.organizationTypes.map((value, index) => (
          <Typography variant="body2" key={index}>{value.name}</Typography>))
      }</>
    )
  },
}, {
  Header: `${t("productType.ouvrageType", "Type d'ouvrages")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<ProductType>) => {
    return (
      <>{
        row.original.ouvrageTypes.map((value, index) => (
          <Typography variant="body2" key={index}>{value.name}</Typography>))
      }</>
    )
  },
}, {
  Header: `${t("productType.total", "Total")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<ProductType>) => (
    <Typography variant="body2"
    >{row.original.total}</Typography>
  ),
}])
