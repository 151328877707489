import {useTranslation} from "react-i18next";
import {useAuth} from "@smartb/g2-providers";
import {useMemo} from "react";
import {OrganizationEditPage} from "../OrganizationEditPage";

export const MyOrganization = () => {
  const {t} = useTranslation()
  const { service } = useAuth()
  const memberOf = useMemo(() => {
    return service.getUser()?.memberOf
  }, [service.getUser])
  return (
    <OrganizationEditPage title={t("organization.mine")} organizationId={memberOf}/>
  )
}
