import {city} from "tracabois-platform-woodSpecies-domain";

export type WoodSpeciesId = string

export type WoodSpeciesCreateCommand = city.smartb.tracabois.platform.s2.woodSpecies.domain.command.WoodSpeciesCreateCommandDTO
export type WoodSpeciesCreatedEvent = city.smartb.tracabois.platform.s2.woodSpecies.domain.command.WoodSpeciesCreatedEventDTO

export type WoodSpeciesUpdateCommand = city.smartb.tracabois.platform.s2.woodSpecies.domain.command.WoodSpeciesUpdateCommandDTO
export type WoodSpeciesUpdatedEvent = city.smartb.tracabois.platform.s2.woodSpecies.domain.command.WoodSpeciesUpdatedEventDTO

export type WoodSpeciesDeleteCommand = city.smartb.tracabois.platform.s2.woodSpecies.domain.command.WoodSpeciesDeleteCommandDTO
export type WoodSpeciesDeletedEvent = city.smartb.tracabois.platform.s2.woodSpecies.domain.command.WoodSpeciesDeletedEventDTO

export type WoodSpecies = city.smartb.tracabois.platform.s2.woodSpecies.domain.model.WoodSpeciesModelDTO

export const WoodSpeciesState = {
  Exist: city.smartb.tracabois.platform.s2.woodSpecies.domain.automate.WoodSpeciesState.Exists,
  Deleted: city.smartb.tracabois.platform.s2.woodSpecies.domain.automate.WoodSpeciesState.Deleted
}
