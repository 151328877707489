import L, {Layer, Map, PathOptions} from 'leaflet';
import * as geojson from "geojson";
import {FeatureCollection} from "geojson";

import charpentier from "./asset/charpentier.png";
import exploitantforestier from "./asset/exploitantforestier.png";
import lamelliste from "./asset/lamelliste.png";
import menuisier from "./asset/menuisier.png";
import negoce from "./asset/negoce.png";
import realization from "./asset/realization.png";
import scieur from "./asset/scieur.png";

export type ActorType =
  "charpentier" |
  "exploitantforestier" |
  "lamelliste" |
  "menuisier" |
  "negoce" |
  "scieur"

const markerByActor: { [index: string]: string } = {
  charpentier: charpentier,
  exploitantforestier: exploitantforestier,
  lamelliste: lamelliste,
  menuisier: menuisier,
  negoce: negoce,
  realization: realization,
  scieur: scieur,
}

export const transformLayerToJson = (layer: L.Circle): FeatureCollection<any, any> => {
  const json = layer.toGeoJSON();
  json.properties.radius = layer.getRadius();
  return {
    type: "FeatureCollection",
    features: [json]
  };
}

var icon = (actor: ActorType) => {
  let iconUrl = markerByActor[actor]
  if(!iconUrl) {
    console.log("///////////////////////////////////////////////////////////")
    console.log("///////////////////////////////////////////////////////////")
    console.log(`Icon for ${actor} not found, use ${realization}`)
    console.log("///////////////////////////////////////////////////////////")
    iconUrl = realization
  }
  return new L.Icon({
    iconUrl: iconUrl,
    iconSize: [30, 36],
    iconAnchor: [15, 36],
    shadowUrl: '/btmc',
  });
}

export const createLayersFromJson = (data: geojson.FeatureCollection, map: Map) => {
  const layers: Layer[] = [];
  data.features.map((geo: geojson.GeoJsonObject) => {
    L.geoJSON(geo, {
      style: (feature?: geojson.Feature<geojson.GeometryObject, any>): PathOptions => {
        if(feature?.properties?.color) {
          return {
            color: feature.properties.color
          }
        }

        return {}
      },
      pointToLayer: (feature, latlng) => {
        const actor = feature.properties.type ?? "exploitantforestier"
        if (feature.properties.radius) {
          return L.layerGroup(
            [
              new L.Marker(latlng,{icon: icon(actor)}),
              new L.Circle(latlng, feature.properties.radius, {
                color: feature.properties.color
              })
            ]
          )
        } else {
          return new L.Marker(latlng, {icon: icon(actor)});
        }
      },
      onEachFeature: (_, layer) => {
        layers.push(layer);
      },
    }).addTo(map);
  })
  return layers
};

