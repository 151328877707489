import { RemovalZoneCard } from "./RemovalZoneCard";
import { Box } from "@mui/material";
import { RemovalZone } from "../../model/removalZone";

export interface RemovalZoneCardStackProps {
  items: RemovalZone[]
}

export const RemovalZoneCardStack = (props: RemovalZoneCardStackProps) => {
  const { items } = props
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
        gridGap: "16px",
        justifyContent: "center"
      }}
    >
      {items.map((item, index) => (
        <RemovalZoneCard item={item} key={`Grid-${index}`} />
      )
      )}
    </Box>
  )
}
