import {city} from "tracabois-platform-organizationType-domain";

export type OrganizationTypeId = string

export type OrganizationTypeCreateCommand = city.smartb.tracabois.platform.s2.organizationType.domain.command.OrganizationTypeCreateCommandDTO
export type OrganizationTypeCreatedEvent = city.smartb.tracabois.platform.s2.organizationType.domain.command.OrganizationTypeCreatedEventDTO

export type OrganizationTypeUpdateCommand = city.smartb.tracabois.platform.s2.organizationType.domain.command.OrganizationTypeUpdateCommandDTO
export type OrganizationTypeUpdatedEvent = city.smartb.tracabois.platform.s2.organizationType.domain.command.OrganizationTypeUpdatedEventDTO

export type OrganizationTypeDeleteCommand = city.smartb.tracabois.platform.s2.organizationType.domain.command.OrganizationTypeDeleteCommandDTO
export type OrganizationTypeDeletedEvent = city.smartb.tracabois.platform.s2.organizationType.domain.command.OrganizationTypeDeletedEventDTO

export type OrganizationType = city.smartb.tracabois.platform.s2.organizationType.domain.model.OrganizationTypeModelDTO
export type Organization = city.smartb.tracabois.platform.s2.organizationType.domain.model.OrganizationDTO
export type OrganizationRef = city.smartb.tracabois.platform.s2.commons.domain.model.OrganizationRefDTO
export type ObjectRef = city.smartb.tracabois.platform.s2.commons.domain.model.ObjectRefDTO

export const OrganizationTypeState = {
  Exist: city.smartb.tracabois.platform.s2.organizationType.domain.automate.OrganizationTypeState.Exists,
  Deleted: city.smartb.tracabois.platform.s2.organizationType.domain.automate.OrganizationTypeState.Deleted
}
