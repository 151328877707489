export * from './FormComposableArray'

import {
  FormComposable as G2FormComposable,
  FormComposableProps,
  FormComposableField as G2FormComposableField,
  ElementRendererFunction,
  WithElementParams
} from "@smartb/g2-composable";

export interface ElementRenderersConfig extends Record<string, ElementRendererFunction<WithElementParams<string, {}>>> {
}


const CustomRenderer: ElementRenderersConfig = {
}

export type FormComposableField =  G2FormComposableField<string, typeof CustomRenderer>

export const FormComposable = (
  props: FormComposableProps<typeof CustomRenderer>
) => {
  return (<G2FormComposable {...props}/>)
}