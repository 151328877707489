import {city} from "tracabois-platform-removalZone-domain";

export type RemovalZoneCreateCommand = city.smartb.tracabois.platform.s2.removalZone.domain.command.RemovalZoneCreateCommandDTO
export type RemovalZoneCreatedEvent = city.smartb.tracabois.platform.s2.removalZone.domain.command.RemovalZoneCreatedEventDTO

export type RemovalZoneUpdateCommand = city.smartb.tracabois.platform.s2.removalZone.domain.command.RemovalZoneUpdateCommandDTO
export type RemovalZoneUpdatedEvent = city.smartb.tracabois.platform.s2.removalZone.domain.command.RemovalZoneUpdatedEventDTO

export type RemovalZoneDeleteCommand = city.smartb.tracabois.platform.s2.removalZone.domain.command.RemovalZoneDeleteCommandDTO
export type RemovalZoneDeletedEvent = city.smartb.tracabois.platform.s2.removalZone.domain.command.RemovalZoneDeletedEventDTO

export type RemovalZonePageQuery = city.smartb.tracabois.platform.s2.removalZone.domain.query.RemovalZonePageQueryDTO
export type RemovalZonePageResponse = city.smartb.tracabois.platform.s2.removalZone.domain.query.RemovalZonePageResponseDTO

export type RemovalZoneGetQuery = city.smartb.tracabois.platform.s2.removalZone.domain.query.RemovalZoneGetQueryDTO
export type RemovalZoneGetResponse = city.smartb.tracabois.platform.s2.removalZone.domain.query.RemovalZoneGetResponseDTO

export type RemovalZone = city.smartb.tracabois.platform.s2.removalZone.domain.model.RemovalZoneModelDTO

export const RemovalZoneState = {
  Exist: city.smartb.tracabois.platform.s2.removalZone.domain.automate.RemovalZoneState.Exists,
  Deleted: city.smartb.tracabois.platform.s2.removalZone.domain.automate.RemovalZoneState.Deleted
}


export type RemovalZoneId = string
