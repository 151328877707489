import {PopUp} from '@smartb/g2-layout'
import {Typography} from "@mui/material";
import React from "react";

export interface PopupFormProps {
  open: boolean;
  onClose: () => void;
  title: string
  children: React.ReactNode
}

export const PopupForm = (props: PopupFormProps) => {
  const {open, onClose, children, title} = props
  return (
    <>
      <PopUp
        open={open}
        onClose={onClose}

        styles={{ actions: { flexDirection: 'column' } }}
      >
        <Typography
          variant='h4'
          style={{ marginBottom: '15px' }}
          align='center'
        >
          {title}
        </Typography>
        {children}
      </PopUp>
    </>
  )
};
