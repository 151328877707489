import {
  BtmcOrganization
} from "../../../model";
import {PlatformSubSection} from "@tracabois/template";
import {DropPictureError} from "@smartb/g2-components";
import {BrandNumberForm} from "./BrandNumberForm";
import {FormComposableState} from "@smartb/g2-composable";
import { brandConfig } from "@tracabois/domain";

export interface BrandNumberSectionProps {
  form: FormComposableState
  item?: BtmcOrganization,
  dropZonePictureAction: DropZonePictureAction
}

export interface DropZonePictureAction {
  errorMessage?: string
  image?: string
  initialPicture?: string
  onDropError: (errorType: DropPictureError) => void
  onPictureDropped: (image: File) => void
  onRemovePicture: () => void
}


export const BrandNumberSection = (props: BrandNumberSectionProps) => {
  const {item, form, dropZonePictureAction} = props

  return (
    <PlatformSubSection title={brandConfig().acronym}>
      {!!item &&
      <BrandNumberForm
          form={form}
          dropZonePicture={dropZonePictureAction}
          item={item}
      />
      }
    </PlatformSubSection>
  )
}
