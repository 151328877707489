import React from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import {Button} from '@smartb/g2-components';
import {AddIcon} from "../asset/addIcon";

export interface DividerAddProps {
  onClick: () => void;
  icon?: React.ReactElement;
  text?: string;
  readOnly: boolean
  loading: boolean
}

export const DividerAdd = (props: DividerAddProps) => {
  const {onClick, icon = (<AddIcon/>), text, readOnly, loading} = props
  return (
    <Stack>
      <Divider>
        {!readOnly && !loading && <Button style={{margin: 0}} onClick={onClick} variant="text">{icon}</Button>}
      </Divider>
      {!readOnly && !loading &&!!text && <Button onClick={onClick} variant="text">{text}</Button>}
    </Stack>
  )
};
