import {city} from "tracabois-platform-product-domain";
import {city as query} from "tracabois-platform-product-query-domain";
import {S2StateProp} from "@tracabois/utils";

export type ProductCreateCommand = city.smartb.tracabois.platform.s2.product.domain.command.ProductCreateCommandDTO
export type ProductCreatedEvent = city.smartb.tracabois.platform.s2.product.domain.command.ProductCreatedEventDTO

export type ProductRenameCommand = city.smartb.tracabois.platform.s2.product.domain.command.ProductRenameCommandDTO
export type ProductRenamedEvent = city.smartb.tracabois.platform.s2.product.domain.command.ProductRenamedEventDTO

export type ProductUpdateCommand = city.smartb.tracabois.platform.s2.product.domain.command.ProductUpdateCommandDTO
export type ProductUpdatedEvent = city.smartb.tracabois.platform.s2.product.domain.command.ProductUpdatedEventDTO

export type ProductUnlockCommand = city.smartb.tracabois.platform.s2.product.domain.command.ProductUnlockCommandDTO
export type ProductUnlockedEvent = city.smartb.tracabois.platform.s2.product.domain.command.ProductUnlockedEventDTO

export type ProductFinishCommand = city.smartb.tracabois.platform.s2.product.domain.command.ProductFinishCommandDTO
export type ProductFinishedEvent = city.smartb.tracabois.platform.s2.product.domain.command.ProductFinishedEventDTO

export type ProductDeleteCommand = city.smartb.tracabois.platform.s2.product.domain.command.ProductDeleteCommandDTO
export type ProductDeletedEvent = city.smartb.tracabois.platform.s2.product.domain.command.ProductDeletedEventDTO

export type ProductTransformationDateChangeCommand = city.smartb.tracabois.platform.s2.product.domain.command.ProductTransformationDateChangeCommandDTO
export type ProductTransformationDateChangedEvent = city.smartb.tracabois.platform.s2.product.domain.command.ProductTransformationDateChangedEventDTO

export type ProductTransformationActorDefineCommand = city.smartb.tracabois.platform.s2.product.domain.command.ProductTransformationActorDefineCommandDTO
export type ProductTransformationActorDefinedEvent = city.smartb.tracabois.platform.s2.product.domain.command.ProductTransformationActorDefinedEventDTO

export type ProductGetQuery = query.smartb.tracabois.platform.s2.product.domain.query.ProductModelGetQueryDTO
export type ProductGetResponse = query.smartb.tracabois.platform.s2.product.domain.query.ProductModelGetResponseDTO


export type ProductPageQuery = query.smartb.tracabois.platform.f2.product.query.domain.query.ProductPageQueryDTO

export type ProductModel = query.smartb.tracabois.platform.s2.product.domain.model.ProductModelDTO
export type Product = query.smartb.tracabois.platform.f2.product.query.domain.model.ProductDTO
export type ProductDestination = city.smartb.tracabois.platform.s2.product.domain.model.DestinationModelDTO
export type ProductSource = city.smartb.tracabois.platform.s2.product.domain.model.SourceModelDTO
export type ProductTransformation = city.smartb.tracabois.platform.s2.product.domain.model.TransformationModelDTO

export type EndProductModel = city.smartb.tracabois.platform.s2.product.domain.model.EndProductModelDTO
export type RawProductModel = city.smartb.tracabois.platform.s2.product.domain.model.RawProductModelDTO
export type ProductMetrics = city.smartb.tracabois.platform.s2.product.domain.model.ProductMetricsDTO

export type OriginModelDTO = city.smartb.tracabois.platform.s2.product.domain.model.OriginModelDTO
export type OriginZoneModelDTO = city.smartb.tracabois.platform.s2.product.domain.model.OriginZoneModelDTO

export type ProductId = string
export type ProductSection = "to" | "from"

export const canFinishProduct = (status?: S2StateProp): boolean => {
  return status?.key == "Exists"
}

export const isEditableProduct = (item?: Product | ProductModel): boolean => {
  // @ts-ignore
  return item?.status == "Exists"
}

export const canUnlockProduct = (status?: S2StateProp): boolean => {
  return status?.key == "Finished" || status?.key == "Deleted"
}



export const toProductState = (item?: Product | ProductModel): S2StateProp => {
  const statue = item?.status
  // @ts-ignore
  if(statue == "Exists") {
    return {
      key: "Exists",
      name: "En cours",
      color: "info",
    }
  }
  // @ts-ignore
  if(statue == "Finished") {
    return {
      key: "Finished",
      name: "Terminé",
      color: "success"
    }
  } else {
    return {
      key: "Deleted",
      name: "Supprimé",
      color: "error"
    }
  }
}
