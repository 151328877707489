import {config} from "./index";
import {RequestProps} from "@smartb/g2-utils";
import {useExtendedAuth} from "@tracabois/connect";

export const noAuthenticatedRequest = (/*path: string*/): RequestProps => {
  return {
    // path: path,
    url: config.baseUrl
  }
}
export const useAuthenticatedRequest = (/*path: string*/): RequestProps => {
  const auth = useExtendedAuth()
  return {
    // path: path,
    url: config.baseUrl,
    jwt: auth.keycloak.token
  }
}
