import {
  RealizationModel,
  RealizationAssignedMoeEvent,
  RealizationAssignMoeCommand,
  RealizationCreateCommand,
  RealizationCreatedEvent,
  RealizationDeleteCommand,
  RealizationDeletedEvent, RealizationFinishCommand, RealizationFinishedEvent,
  RealizationGetQuery,
  RealizationGetResponse, RealizationRef,
  RealizationUpdateCommand,
  RealizationUpdatedEvent, Realization
} from "../model/realizationModel";
import {config} from "../../../config";
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {PageQuery, PageResult} from "@tracabois/utils";
import {authQuery} from "../../../utils/authQuery";

export const RealizationApi = createApi({
  reducerPath: 'realizationApi',
  baseQuery: authQuery({baseUrl: config.baseUrl}),
  tagTypes: ['Realization'],
  endpoints: (builder) => ({
    getRealizationById: builder.query<Realization | undefined, RealizationGetQuery>({
      query: (body: RealizationGetQuery) => ({
        url: `realizationGet`,
        method: 'POST',
        body: body
      }),
      transformResponse: (response: RealizationGetResponse[]) => response[0]?.item as Realization,
      providesTags: ['Realization'],
    }),
    getRealizationPage: builder.query<PageResult<Realization>, PageQuery>({
      query: (page: PageQuery) => ({
        url: `realizationPage`,
        method: 'POST',
        body: page
      }),
      transformResponse: (response: PageResult<Realization>[]) => response[0],
      providesTags: ['Realization'],
    }),
    realizationRefsAll: builder.query<RealizationRef[], undefined>({
      query: () => ({
        url: `realizationRefs`,
        method: 'POST',
      }),
      providesTags: ['Realization'],
    }),
    realizationCreate: builder.mutation<RealizationCreatedEvent, RealizationCreateCommand>({
      query: (cmd: RealizationCreateCommand) => ({
        url: `realizationCreate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RealizationCreatedEvent[]) => response[0],
      invalidatesTags: ['Realization'],
    }),
    realizationUpdate: builder.mutation<RealizationUpdatedEvent, RealizationUpdateCommand>({
      query: (cmd: RealizationUpdateCommand) => ({
        url: `realizationUpdate`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RealizationUpdatedEvent[]) => response[0],
      invalidatesTags: ['Realization'],
    }),
    realizationDelete: builder.mutation<RealizationDeletedEvent, RealizationDeleteCommand>({
      query: (cmd: RealizationModel) => ({
        url: `realizationDelete`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RealizationDeletedEvent[]) => response[0],
      invalidatesTags: ['Realization'],
    }),
    realizationFinish: builder.mutation<RealizationFinishedEvent, RealizationFinishCommand>({
      query: (cmd: RealizationModel) => ({
        url: `realizationFinish`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RealizationFinishedEvent[]) => response[0],
      invalidatesTags: ['Realization'],
    }),
    realizationUnlock: builder.mutation<RealizationFinishedEvent, RealizationFinishCommand>({
      query: (cmd: RealizationModel) => ({
        url: `realizationUnlock`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RealizationFinishedEvent[]) => response[0],
      invalidatesTags: ['Realization'],
    }),
    realizationAssignMoe: builder.mutation<RealizationAssignedMoeEvent, RealizationAssignMoeCommand>({
      query: (cmd: RealizationAssignMoeCommand) => ({
        url: `realizationAssignMoe`,
        method: 'POST',
        body: cmd
      }),
      transformResponse: (response: RealizationAssignedMoeEvent[]) => response[0],
      invalidatesTags: ['Realization'],
    })
  }),
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true
})

export const {
  useGetRealizationByIdQuery,
  useGetRealizationPageQuery,
  useRealizationRefsAllQuery,
  useRealizationCreateMutation,
  useRealizationUpdateMutation,
  useRealizationDeleteMutation,
  useRealizationFinishMutation,
  useRealizationUnlockMutation,
  useRealizationAssignMoeMutation,
} = RealizationApi
