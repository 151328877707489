import {WoodTicket, RouteTicket, ForestTicket, ArtIndexTicket} from "@tracabois/components";
import {RealizationIndicator} from "../../model/realizationModel";
import Box from "@mui/material/Box";

export interface RealizationIndicatorGridProps {
  indicators: RealizationIndicator
}

export const RealizationIndicatorGrid = (props: RealizationIndicatorGridProps) => {
  const {indicators} = props
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-around">
      <WoodTicket indicateur={indicators.wood}/>
      <RouteTicket indicateur={indicators.route}/>
      {/*<Co2Ticket indicateur={indicators.co2}/>*/}
      <ForestTicket indicateur={indicators.forest}/>
      {indicators.artIndex && <ArtIndexTicket indicateur={indicators.artIndex}/>}
    </Box>
  )
};
