import {Chip as MuiChip, Typography} from "@mui/material";
import {ChipIcon} from "./ChipIcon";
import {useTheme} from "@smartb/g2-themes";

export type ChipColor = "primary" | "secondary" |
  "info" | "error" |
  "success" | "warning"

export interface ChipProps {
  label: string
  color?: ChipColor
  onClick?: () => void
}

export const Chip = (props: ChipProps) => {
  const {label, color = "primary", onClick} = props
  const [base, light] = useColor(color)
  return (
    <MuiChip
      sx={{width: 150, marginBottom: 1}}
      icon={<ChipIcon color={base}/>}
      label={<Typography color={base} variant="body2">{label}</Typography>}
      style={{backgroundColor: light}}
      variant="filled"
      onClick={onClick}
    />
  )
}

const useColor = (color: ChipColor) => {
  const {colors} = useTheme()
  const base = colors[color]
  return [base, `${base}25`]

}
