import {useCallback} from 'react'
import {OuvrageCreateCommand} from "../../../model/ouvrage";
import {OuvrageType} from "../../../../../referentiels/ouvrageType";
import {useFormComposable, useFormFieldComposable} from "@smartb/g2";
import {FormComposable} from "@tracabois/components";

export interface OuvrageFormProps {
  onSubmit?: (item: OuvrageCreateCommand) => Promise<boolean> | boolean
  onCancel?: () => void
  realizationId?: string
  ouvrageTypes: OuvrageType[]
}

export const OuvrageCreateForm = (props: OuvrageFormProps) => {
  const {
    onSubmit,
    onCancel,
    realizationId,
    ouvrageTypes
  } = props

  const onSubmitMemoized = useCallback(
    async (values: OuvrageCreateCommand): Promise<boolean> => {
      return onSubmit ? onSubmit({
        ...values,
        realisation: realizationId ? {
          id: realizationId
        } : undefined,
      }) : false
    },
    [onSubmit]
  )

  const validate = (values: OuvrageCreateCommand) => {
    const errors:  Record<string,any> = {};
    const trimmed = (values?.name ?? '').trim()
    if (!trimmed) {
      errors["name"] = "Vous devez renseigner le type de l'ouvrage";

    }
    return errors;
  };

  const form = useFormComposable<OuvrageCreateCommand>({
    onSubmit: onSubmitMemoized,
    formikConfig: {
      validate: validate,
      initialValues: {}
    },
    actions: {
      validate: {
        label: "Créer",
      },
      cancel: {
        onClick: onCancel,
      }
    }
  })

  const formFields = useFormFieldComposable({
    fields: [
      {
        key: 'name',
        name: 'name',
        type: 'autoComplete',
        label: "Type de l'ouvrage",
        params: {
          placeholder: 'Sélectionner',
          options: ouvrageTypes.map((item) => ({
            key: item.name,
            label: item.name
          }))
        }
      },
    ],
  },[ouvrageTypes])

  return (
    <FormComposable
      fields={formFields.fields}
      formState={form}
      actions={form.actions}
      actionsProps={{
        direction: 'row',
        justifyContent: 'flex-end',
      }}
    />
  )
}
