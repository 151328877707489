import {fetchBaseQuery} from "@reduxjs/toolkit/query";
import {keycloakAuth} from "../s2/KeycloakAuth";
import {config} from "../config";

interface BaseQueryProps {
  baseUrl?: string | null
}

export const authQuery = (props?: BaseQueryProps) => fetchBaseQuery({
  prepareHeaders: (headers) => {
    const token =  keycloakAuth()?.instance?.token
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
  baseUrl: props?.baseUrl == null ? "" : props?.baseUrl || config.baseUrl
})
