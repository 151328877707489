import React from "react";
import { AppProvider, ThemeContextProvider, AlertHub } from "@smartb/g2";
import App from "App";
import { AppLoading } from "@tracabois/components";
import { languages, theme } from "@tracabois/domain";
import { KeycloakProvider, G2ConfigBuilder, g2Config } from '@smartb/g2-providers'
import { QueryClient } from 'react-query'
import { KeycloakAuthFactory } from "@tracabois/domain";
import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";
import {store} from "./store";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 86400000,
      refetchOnMount: "always"
    }
  }
})

// @ts-ignore
G2ConfigBuilder(window._env_.config)

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<KeycloakProvider
  loadingComponent={<AppLoading />}
  instance={KeycloakAuthFactory(g2Config().keycloak)}
>
  <React.StrictMode>
    <ThemeContextProvider theme={theme}>
        <AppProvider
          languages={languages}
          loadingComponent={<AppLoading />}
          queryClient={queryClient}
        >
            <Provider store={store}>
              <AlertHub
                autoHideDuration={3000}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
              >
                <App />
              </AlertHub>
            </Provider>
        </AppProvider>
    </ThemeContextProvider>
  </React.StrictMode>
</KeycloakProvider>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
