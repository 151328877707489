import {PlatformPage, PlatformSection} from "@tracabois/template";
import {
  Realization,
  RealizationTable,
  brandConfig,
  useGetRealizationPageQuery, useGetStatsQuery,
} from "@tracabois/domain";
import {usePageQuery, IndicatorGrid} from "@tracabois/components";
import {Row} from "@smartb/g2-layout";
import {realizationRoute} from "../realization/route";
import {Paper, Stack, Typography} from "@mui/material";
import {Button} from '@smartb/g2-components';
import {productRoute} from "../product/route";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export interface HomeProps {
}

export const Home = (_: HomeProps) => {
  const realizationPageQuery = usePageQuery(useGetRealizationPageQuery);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const clickHandler = (row: Row<Realization>) => {
    realizationRoute.goto.viewRealization(row.original.id, navigate)
  }

  const gotoAddProduct = () => {
    productRoute.goto.addProduct(navigate)
  }
  const {data, isLoading} = useGetStatsQuery({});

  return (
    <PlatformPage title={t("home")}>
      <PlatformSection>
        <Paper sx={{
          padding: 2
        }}>
          <Stack sx={{width: "100%"}} direction="row" justifyContent="space-between">
            <Stack>
              <Typography variant="body1" color="text.secondary">
                Bienvenue,<br/>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Grâce à l’application traçabois vous allez pouvoir valoriser votre participation aux réalisations {brandConfig().title}.
                Ajoutez votre premier produit en sélectionnant une réalisation ou en cliquant sur
                <Button variant="text" onClick={gotoAddProduct}>ajouter un produit</Button>
              </Typography>
            </Stack>
            <Stack>
              <Button onClick={gotoAddProduct}>Ajouter un produit</Button>
            </Stack>
          </Stack>
        </Paper>
      </PlatformSection>
      <PlatformSection>
        <IndicatorGrid isLoading={isLoading}  indicators={data?.item}/>
      </PlatformSection>
      <PlatformSection>
        <RealizationTable
          onRowClicked={clickHandler}
          page={realizationPageQuery.data}
          isLoading={realizationPageQuery.isLoading}
          onPageQueryChange={realizationPageQuery.setPageQuery}
        />
      </PlatformSection>
    </PlatformPage>
  );
}
