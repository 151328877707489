import React from 'react'
import { ReactComponent as Z } from './0.svg'
import { ReactComponent as T} from './25.svg'
import { ReactComponent as F} from './50.svg'
import { ReactComponent as S} from './75.svg'
import { ReactComponent  as H} from './100.svg'
import { MergeReactElementProps } from '@smartb/g2-utils'

interface CompletionProps {
  completion?: number
}

type Props = MergeReactElementProps<'svg', CompletionProps>

export const CompletionIcon = React.forwardRef(
  (props: Props, ref: React.Ref<SVGSVGElement>) => {
    const {completion} = props
    if(completion == 0) {
      return <Z ref={ref} {...props} />
    } else if(completion == 25) {
      return <T ref={ref} {...props} />
    } else  if(completion == 50) {
      return <F ref={ref} {...props} />
    } else  if(completion == 75) {
      return <S ref={ref} {...props} />
    } else {
      return <H ref={ref} {...props} />
    }
  }
)
