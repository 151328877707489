import { DocumentHandler as G2DocumentHandler, DocumentHandlerProps } from '@smartb/g2'
import { useTranslation } from 'react-i18next'

export const DocumentHandler = (props: DocumentHandlerProps) => {
    const {t} = useTranslation()
    return (
        <G2DocumentHandler
            errorMessages={{
                "file-invalid-type": t("imForms.fileInvalidType"),
                "file-too-large": t("imForms.fileTooLarge", { size: 10 }),
                "too-many-files": t("imForms.tooManyFiles"),
            }}
            dropzoneProps={{
                maxSize: 10 * 1024 * 1024
            }}
            isRequiredLabel={t("required")}
            {...props}
        />
    )
}
