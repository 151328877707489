import {CellProps, Column, Row} from '@smartb/g2-layout'
import {TFunction} from 'react-i18next'
import {
  PlatformPaginatedTable,
  TableCellText,
  TableCellTag,
  PlatformActions
} from "@tracabois/template";
import {OffsetPagination, PageQuery, PageResult} from "@tracabois/utils";
import {ProductModel} from "../../model/product";
import React from "react";
import {ProductStateChip} from "../state/ProductStateChip";

interface OuvrageProductTableProps {
  page: PageResult<ProductModel>
  isLoading?: boolean
  onPageQueryChange: (page: PageQuery) => void;
  onRowClicked: (row: Row<ProductModel>) => void;
  actions?: React.ReactNode
}

export const OuvrageProductTable = (props: OuvrageProductTableProps) => {
  const {page, isLoading, onPageQueryChange, onRowClicked, actions} = props

  const filter = (
    actions && <PlatformActions actions={actions} />
  )
  const onPaginationChange = (pagination: OffsetPagination) => {
    onPageQueryChange({
      pagination: pagination
    })
  }
  return (
    <PlatformPaginatedTable<ProductModel>
      page={page}
      columnsDefinition={getColumns}
      isLoading={isLoading}
      actions={filter}
      onPageChange={onPaginationChange}
      onRowClicked={onRowClicked}

    />
  )
}

const getColumns = (t: TFunction): Column<ProductModel>[] => ([{
  Header: `${t("product.name", "Nom")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<ProductModel>) => (
    <TableCellText main={row.original.name}/>
  )
}, {
  Header: `${t("product.status", "Status")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<ProductModel>) => (
    <TableCellTag
      main={<ProductStateChip item={row.original} editable={false}/>}
      second={`Créé le ${new Date(row.original.creationDate).toLocaleDateString("fr-FR")}`}
    />
  )
}, {
  Header: `${t("product.volumes", "Volume")}`,
  // @ts-ignore
  Cell: ({row}: CellProps<ProductModel>) => (
    <TableCellText main={`${row.original.volume.value} ${row.original.volume.unit}`}/>
  )
}])

