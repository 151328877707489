import {
  isEditableProduct,
  ProductDateForm,
  ProductForm,
  ProductRenameCommand,
  ProductTransformationDateChangeCommand,
  ProductTransformerForm,
  useGetProductByIdQuery,
  useProductChangeDateMutation, useProductDeleteMutation,
  useProductRenameMutation
} from "@tracabois/domain";
import {Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useCallback, useEffect, useMemo, useState} from "react";
import {PlatformPageObject, PlatformSubSection} from "@tracabois/template";
import {useNavigate, useParams} from "react-router";
import {ObjectHeader, useValidationButton} from "@tracabois/components";
import {ProductStateChip} from "@tracabois/domain";
import {OrganizationRef} from "@tracabois/web-certificate/src/App/model";
import {DeleteButton} from "@tracabois/components";
import {goto} from "../route/product.goto";
import {useExtendedAuth} from "@tracabois/connect";
import {FormComposableState} from "@smartb/g2";

export interface ProductViewPagePros {
}

const useLabels = () => {
  const {t} = useTranslation()
  return useMemo(() => ({
    actor: t("product.view.transformation.acteur.label", "Acteur de la transformation"),
    ouvrage: t("product.view.transformation.acteur.label", "Ouvrage"),
    date: t("product.view.transformation.date.label", "Date de livraison"),
  }), [t])
}

interface ProductEditPageState {
  productRenameCommand: ProductRenameCommand | null
  changeDateCommand: ProductTransformationDateChangeCommand | null
}

export const ProductEditPage = (_: ProductViewPagePros) => {
  const labels = useLabels()
  const navigate = useNavigate()
  const {service} = useExtendedAuth()

  const {productId = ""} = useParams<{ productId: string }>();
  const productResult = useGetProductByIdQuery({id: productId})

  const isEditable = isEditableProduct(productResult?.data)
  const [productForm, setProductForm] = useState<FormComposableState | null>(null)
  const [productTransformationForm, setProductTransformationForm] = useState<FormComposableState | null>(null)
  const [state, setState] = useState<ProductEditPageState>({
    productRenameCommand: null,
    changeDateCommand: null
  })

  const [productRename] = useProductRenameMutation()
  const [productChangeDate] = useProductChangeDateMutation()
  const [productDelete] = useProductDeleteMutation()

  const [transformer, setTransformer] = useState<OrganizationRef | undefined>(productResult?.data?.transformation?.actor)
  useEffect(() => {
    setTransformer(productResult?.data?.transformation?.actor)
  }, [productResult?.data?.transformation?.actor])


  const deleteAction = useCallback(async (): Promise<boolean> => {
    await productDelete({
      id: productId,
    })
    goto.listProduct(navigate)
    return true;
  }, [productId, productDelete])


  const updateState = useCallback((field: keyof ProductEditPageState, value: any) => {
    const newState = {...state}
    newState[field] = value
    setState(newState)
  }, [state])

  const handleTitleChanged = useCallback(async (name: string) => {
    updateState("productRenameCommand", {
      id: productId,
      name: name,
    })
  }, [productId, updateState])

  const [saving, setSaving] = useState(false)

  const submitChanges = useCallback(async () => {
    setSaving(true)
    state.changeDateCommand && await productChangeDate(state.changeDateCommand)
    if(service.isMarque()) {
      productForm && await productForm.submitForm()
      await new Promise(resolve => setTimeout(resolve, 5000))
    }
    state.productRenameCommand && await productRename(state.productRenameCommand)
    productTransformationForm && await productTransformationForm.submitForm()
    productResult.refetch()
    setState({
      productRenameCommand: null,
      changeDateCommand: null
    })
    setSaving(false)
    return true
  }, [state, productChangeDate, productRename, productResult, productForm, productTransformationForm])
  const [actionButton] = [
    useValidationButton(submitChanges, [state, productChangeDate, productRename, productResult])
  ]
  const date = useMemo(() => {
    const time = state.changeDateCommand?.date ?? productResult?.data?.transformation?.date
    return time ? new Date(time) : null
  }, [state.changeDateCommand, productResult.data?.transformation?.date])
  const handleStateChanged = useCallback(() => {
    productResult.refetch()
  }, [productResult])

  const name = useMemo(() => {
    return state.productRenameCommand?.name ?? productResult.data?.name
  }, [state.productRenameCommand, productResult.data?.name])

  const loading = productResult.isLoading || saving
  const readOnly = false
  return (
    <PlatformPageObject>
      <ObjectHeader
        title={name || ""}
        editableTitle={isEditable && !readOnly && !loading}
        onTitleChange={handleTitleChanged}
        actions={
          <Stack direction="row" spacing={4}>
            <>
              { !loading && isEditable && <DeleteButton onClick={deleteAction} />}
              { !loading && <ProductStateChip item={productResult.data} editable onStateChanged={handleStateChanged}/>}
              { isEditable && actionButton }
            </>
          </Stack>
        }
      />
      <Stack sx={{
        flexDirection: {
          md: "column",
          lg: "row"
        },
        alignItems: {
          md: "center",
          lg: "flex-start"
        }
      }} justifyContent="center" gap={2}>
        <Stack width="350px" flexShrink={0}>
          <PlatformSubSection title={labels.actor}>
            <ProductTransformerForm
              productId={productId}
              onChange={setTransformer}
              transformation={productResult?.data?.transformation}
              onFormReady={(it) => setProductForm(it)}
              readOnly={saving}
              loading={loading}
            />
          </PlatformSubSection>
          <PlatformSubSection title={labels.date}>
            <ProductDateForm
              date={date}
              productId={productId}
              onDateChange={(it) => updateState("changeDateCommand", it)}
              loading={loading}
              readOnly={readOnly}
            />
          </PlatformSubSection>
        </Stack>
        <Stack flexGrow={1}>
          <ProductForm
            transformer={transformer}
            product={productResult.data}
            onFormReady={(it) => setProductTransformationForm(it)}
            readOnly={readOnly}
            loading={loading}
          />
        </Stack>
      </Stack>
    </PlatformPageObject>
  );
}
