import {PlatformSubSection} from "@tracabois/template";
import {SourceForm} from "./SourceForm/SourceForm";
import {RemovalZoneFormCreatePopupButton} from "../../../../../removalZone/components/form/RemovalZoneFormCreatePopupButton";
import {OriginForm} from "./OriginForm/OriginForm";
import {ProviderFields} from "./useProviderFields";
import {RemovalZoneCreatedEvent} from "../../../../../removalZone";
import {OrganizationRef} from "@tracabois/web-certificate/src/App/model";
import {FormComposableState} from "@smartb/g2";

export interface ProviderFormSectionProps {
  formState: FormComposableState
  fields: ProviderFields
  transformer?: OrganizationRef
  handleRemovalZoneCreated?: (evt: RemovalZoneCreatedEvent) => void
}

export const ProviderFormSection = (props: ProviderFormSectionProps) => {
  const {fields, formState, transformer, handleRemovalZoneCreated} = props
  const {originFields, sourceFields, addLine, deleteLine} = fields
  return (
    <>
      {sourceFields &&
        <PlatformSubSection title="Approvisionnement" elevation={1}>
            <SourceForm
                fields={sourceFields}
                formState={formState}
                addRawProduct={addLine}
                deleteProduct={deleteLine}
            />
        </PlatformSubSection>
      }
      {originFields &&
        <PlatformSubSection title="Approvisionnement" elevation={1}>
          {transformer && !formState.readonly && !formState.isLoading && <RemovalZoneFormCreatePopupButton
              label={"Ajout d'une origine"}
              actor={transformer.id}
              onRemovalZoneCreated={handleRemovalZoneCreated}/>
          }
            <OriginForm
                originFields={originFields}
                formState={formState}
                addRawProduct={addLine}
                deleteProduct={deleteLine}
            />
        </PlatformSubSection>
      }
    </>
  )
}
