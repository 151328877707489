import {Stack, Typography} from "@mui/material";
import {InputForm} from "@smartb/g2-forms";
import {Button} from "@smartb/g2-components";
import {useCallback, useState} from "react";
import {EditIcon} from "./asset/edit";

interface NameFormProps {
  name: string;
  onSave?: (name: string) => void;
  onChange?: (name: string) => void;
}

// TODO Should Propably not be in template
export const NameForm = (props: NameFormProps) => {
  const {name, onSave, onChange} = props
  const [edit, setEdit] = useState<boolean>(false)
  const handleSave = async (name:string) => {
    onSave && await onSave(name)
    setEdit(false)
  }
  return edit ? (
    <NameEditForm name={name} onSave={onSave && handleSave} onChange={onChange}/>
  ) : (<NameViewForm name={name} onEdit={() => setEdit(true)}/>)
}

interface NameEditFormProps {
  name: string;
  onSave?: (name: string) => void;
  onChange?: (name: string) => void;
}

export const NameEditForm = (props: NameEditFormProps) => {
  const {name, onSave, onChange} = props
  const [value, setValue] = useState<string>(name)
  const handleChange = useCallback((value: string) => {
    setValue(value)
    onChange && onChange(value)
  }, [name, onChange])
  return (<>
      <Stack direction="row" spacing={2} marginBottom="20px">
        <InputForm inputType="textField" onChange={handleChange} value={value} style={{width: "300px"}}/>
        {onSave && <Button name="Enregistrer" onClick={() => onSave(value)}>Enregistrer</Button>}
      </Stack>
    </>
  )
}

interface NameViewFormProps {
  name: string;
  onEdit?: () => void;
}

export const NameViewForm = (props: NameViewFormProps) => {
  const {name, onEdit} = props
  return (
    <>
      <Stack direction="row" spacing={2} marginBottom="20px" >
        <Typography variant="h4" sx={{textTransform: "capitalize"}} color="text.secondary">{name}</Typography>
        {onEdit &&
        <Button name={"Edit"} variant="text" onClick={() => onEdit()}>
            <EditIcon/>
        </Button>
        }
      </Stack>
    </>
  )
}
