import {Skeleton, Typography} from "@mui/material";

export interface PageHeaderTitleProps {
    title?: string
    isLoading?: boolean
}
export const PageHeaderTitle = (props: PageHeaderTitleProps) => {
    const {title, isLoading = false} = props
    return (
      <Typography color="primary" sx={{textTransform: "capitalize"}} variant="h5" key="pageTitle">
          {isLoading ? <Skeleton key="loadingTitle" animation='wave' sx={{width:250}} /> : title}
      </Typography>
    );
}
