import {Stack} from '@mui/material'
import {DividerAdd} from "@tracabois/components";
import {OriginFieldsArray} from "./withOriginFields";
import {OriginZoneForm} from "./OriginZoneForm";
import {FormComposableState} from "@smartb/g2";

export interface OriginFormProps {
  formState: FormComposableState
  originFields: OriginFieldsArray
  addRawProduct: (fieldName: string) => void
  deleteProduct: (fieldName: string, index: number) => void
}

export const OriginForm = (props: OriginFormProps) => {
  const {
    formState,
    originFields,
    addRawProduct,
    deleteProduct,
  } = props

  return (
    <Stack direction="column" gap={2}>
      {
        originFields.fields.map((lineFields, index) => (
          <OriginZoneForm
            key={index}
            formState={formState}
            fields={lineFields}
            deleteProduct={() => deleteProduct(originFields.fieldName, index)}
          />
        ))
      }
      <DividerAdd
        loading={formState.isLoading}
        readOnly={formState.readonly}
        onClick={() => addRawProduct(originFields.fieldName)}
        text="Ajouter un produit brut"/>
    </Stack>
  )
}
