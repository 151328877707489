import React, {useMemo} from 'react'
import {StandAloneAppLayout} from '@smartb/g2-layout'
import {MenuItems} from '@smartb/g2-components'
import {useExtendedAuth} from "@tracabois/connect";
import {useTranslation} from "react-i18next";

interface PlatformLayoutProps {
    children?: React.ReactNode
    menu: MenuItems[],
    loggedMenu: MenuItems[],
    notLoggedMenu: MenuItems[],
    logo: string,
    website: string,
}

export const PlatformLayout = (props: PlatformLayoutProps) => {
    const { children, menu, logo, loggedMenu, notLoggedMenu, website } = props
    const { t } = useTranslation()
    const { service } = useExtendedAuth()
    const user = useMemo(() => {
        return {
            person: service.getUser(),
            role: service.getUserPrincipalRole()
        }
    }, [service.getUser])

    const handleLogoClick = () => {
        window.open(website,'_blank');
    }
    return (
        <StandAloneAppLayout
            logo ={{src: logo, imgProps:{onClick: handleLogoClick}}}
            menu={menu}
            userMenuProps={{
                currentUser: user ? {
                    givenName: user.person?.firstName ?? "",
                    familyName: user.person?.lastName ?? "",
                    role: t("roles." + user.role)
                } : undefined,
                loggedMenu: loggedMenu,
                notLoggedMenu: notLoggedMenu,
                defaultOpen: false
            }}
        >
            {children}
        </StandAloneAppLayout>
    )
}

