import { PlatformPageObject, PlatformSubSection } from "@tracabois/template";
import { Stack } from "@mui/material";
import {
  defaultRealizationIndicator, isEditableRealizationMoe,
  isEditableRealization,
  RealizationActorTable,
  RealizationIndicatorGrid,
  RealizationMoeSection,
  RealizationOuvrageSection,
  RealizationStateChip,
  RemovalZoneCardStack,
  TransformationActor,
  useGetRealizationByIdQuery, useRealizationDeleteMutation,
  WoodSpeciesCard, RealizationFormPopup, OuvrageCreatedEvent
} from "@tracabois/domain";
import { useNavigate, useParams } from "react-router";
import { PageResult, toPage } from "@tracabois/utils";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { ouvrageRoute } from "../../ouvrage/route";
import { ObjectHeader, QrcodeImg, usePopup } from "@tracabois/components";
import { Gallery, useExtendedAuth } from "@tracabois/connect";
import { DeleteButton } from "@tracabois/components";
import { goto } from "../route/realization.goto";
import { EditButton } from "@tracabois/components";

const useLabels = () => {
  const { t } = useTranslation()
  return useMemo(() => ({
    indicators: t("realization.indicators", "Indicateurs"),
    essence: t("realization.woodSpecies", "Éssences"),
    ouvrage: t("ouvrage.plurals"),
    moe: t("realization.moe", "Mise en oeuvre"),
    harvesting: t("realization.harvesting", "Origines"),
    actor: t("realization.actors", "Contributeurs déjà enregistrés"),
  }), [t])
}

type RealizationIdUrl = {
  realizationId: string
}

export const RealizationViewPage = () => {
  const realizationIndicator = defaultRealizationIndicator
  const navigate = useNavigate()
  const editPopup = usePopup()
  const { realizationId = "" } = useParams<RealizationIdUrl>();
  const labels = useLabels()
  const realizationResult = useGetRealizationByIdQuery({ id: realizationId })
  const [realizationDelete] = useRealizationDeleteMutation()
  const isEditable = isEditableRealization(realizationResult.data)
  const { service } = useExtendedAuth()
  const editableRealizationMoe = isEditableRealizationMoe(service, realizationResult.data)

  const actorsPage: PageResult<TransformationActor> = toPage(realizationResult.data?.actors)

  const nothing = () => { }

  const onRealizationStateChanged = useCallback(() => {
    realizationResult.refetch()
  }, [realizationResult])

  const gotoOuvrage = useCallback((ouvrage: OuvrageCreatedEvent) => {
    ouvrageRoute.goto.viewOuvrage(ouvrage.id, navigate)
  }, [realizationResult])

  const deleteAction = useCallback(async (): Promise<boolean> => {
    await realizationDelete({
      id: realizationId,
    })
    goto.listRealization(navigate)
    return true;
  }, [realizationId, realizationDelete])

  const assignMoe = useCallback(async () => {
    realizationResult.refetch()
    return true
  }, [realizationResult])
  const isLoading = realizationResult.isLoading || realizationResult.isFetching
  return (
    <PlatformPageObject>
      <ObjectHeader
        title={realizationResult.data?.name || ""}
        actions={<>
          {isEditable && <EditButton onClick={editPopup.open} />}
          {isEditable && <DeleteButton onClick={deleteAction} />}
          <RealizationStateChip
            editable onStateChanged={onRealizationStateChanged}
            item={realizationResult.data}
          />
          <QrcodeImg certificateId={realizationId} qrcode={realizationResult.data?.qrcode} />
        </>}
      />
      <RealizationFormPopup type={"edit"} onSaved={onRealizationStateChanged} onClose={editPopup.close} open={editPopup.isOpen} item={realizationResult.data} />
      <Gallery
        editable={isEditable}
        loading={isLoading}
        type='realization'
        id={realizationId}
        name={`${realizationResult.data?.name}`}
      />
      <Stack
        gap={2}
        direction="row"

      >
        <Stack
          gap={2}
          sx={{
            flex: 0.3,
            minWidth: "230px"
          }}
        >
          <PlatformSubSection title={labels.indicators}>
            <RealizationIndicatorGrid indicators={realizationResult.data?.indicators || realizationIndicator} />
          </PlatformSubSection>
          <PlatformSubSection title={labels.essence}>
            <WoodSpeciesCard
              items={realizationResult?.currentData?.species || []}
            />
          </PlatformSubSection>
          <PlatformSubSection title={labels.harvesting}>
            <RemovalZoneCardStack items={realizationResult?.currentData?.removalZones || []} />
          </PlatformSubSection>
        </Stack>
        <Stack
          gap={2}
          sx={{
            flex: 0.7,
          }}
        >
          <RealizationOuvrageSection
            onOuvrageClicked={(item) => ouvrageRoute.goto.viewOuvrage(item.id, navigate)}
            onOuvrageAdded={gotoOuvrage}
            realization={realizationResult.data}
            isLoading={isLoading}
            editable={isEditable}
            title={labels.ouvrage}
          />
          <RealizationMoeSection
            title={labels.moe}
            realization={realizationResult.data}
            onMoeAssigned={assignMoe}
            editable={editableRealizationMoe}
            isLoading={isLoading}
          />
          <PlatformSubSection title={labels.actor}>
            <RealizationActorTable
              page={actorsPage}
              isLoading={isLoading}
              onPageQueryChange={nothing}
              onRowClicked={nothing}
            />
          </PlatformSubSection>
        </Stack>
      </Stack>
    </PlatformPageObject >
  );
}
