import {AutomatedUserTable} from "@smartb/g2-i2";
import {PlatformSectionFilter} from "@tracabois/template";
import {useUserListPage} from "./useUserListPage";
import {useUserFilters} from "./useUserFilters";

export interface UserTablePros {
  organizationId?: string;
}

export const UserTable = (props: UserTablePros) => {
  const {organizationId} = props;
  const { getActions, getOrganizationUrl, onRowClicked, additionnalColumns, blockedColumns } = useUserListPage()
  const { component, submittedFilters, setPage } = useUserFilters({ searchOrg: true })

  const organizationFilter = organizationId ? {organizationId: organizationId} : {}

  return (
    <AutomatedUserTable
      columnsExtander={{
        getActions: getActions,
        additionnalColumns: additionnalColumns,
        blockedColumns: blockedColumns
      }}
      header={(<PlatformSectionFilter>{component}</PlatformSectionFilter>)}
      variant='grounded'
      filters={{...organizationFilter, ...submittedFilters}}
      onRowClicked={onRowClicked}
      page={submittedFilters.page + 1}
      setPage={setPage}
      hasOrganizations
      getOrganizationUrl={getOrganizationUrl}
    />
  );
};
