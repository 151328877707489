import { MenuItem } from "@smartb/g2-components";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { LinkProps } from "react-router-dom";
import { EditRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Row, Column } from "@smartb/g2-layout";
import {User} from "@smartb/g2";
import {useExtendedAuth} from "../../../auth";
import {userRoute} from "../../route";
import {OrganizationId} from "@smartb/g2-i2/dist/Organization";

export const useUserListPage = () => {

  const { t } = useTranslation();
  const navigate = useNavigate()

  const {service} = useExtendedAuth()

  const getActions = useCallback(
    (user: User): MenuItem<LinkProps>[] => {
      return [
       {
          key: "edit",
          label: t("edit"),
          icon: <EditRounded />,
          component: Link,
          componentProps: {
            to: `/users/${user.id}/edit`
          }
        }
      ]
    },
    [],
  )

  const onRowClicked = useCallback(
    (row: Row<User>) => {
      userRoute.goto.editUser(row.original.id, navigate)
    },
    [navigate],
  )

  const getOrganizationUrl = useCallback(
    (organizationId: OrganizationId) => `/organizations/${organizationId}/view`,
    [],
  )

  const additionnalColumns = useMemo((): Column<User>[] => {
    return []
  }, [service.getPrincipalRole])

  const blockedColumns = useMemo(() => [
    "address",
    "email"
  ], [])

  return {
    getActions,
    onRowClicked,
    getOrganizationUrl,
    additionnalColumns,
    blockedColumns
  }
}