import {KeycloackService, useAuth} from "@smartb/g2-providers"

import {TFunction} from "react-i18next";

export const rolesOptions = (t: TFunction) => [{
  key: "admin",
  label: t("roles.admin")
  },
  {
    key: "user",
    label: t("roles.user")
  }]

export const userRolesColors: { [roles in UserRoles]: string } = {
  "super_admin": "#d1b00a",
  "admin": "#E56643",
  "user": "#3041DC"
}

export const userRoles = [ "admin", "user"]
export const organizationRoles = [
  "Exploitant forestier", "Scieur", "Lamelliste", "Négoce", "Charpentier",
  "Menuisier", "COFOR", "Marque", "Maitre d'oeuvre", "Maitre d'ouvrage", "Financeur"
] as const


export const OrgRolesArray = [...userRoles, ...organizationRoles]

export type UserRoles = typeof OrgRolesArray[number]

const mutableUserRoles: UserRoles[] = [...OrgRolesArray]

export const useExtendedAuth = () => useAuth<AuthServices, UserRoles>(mutableUserRoles, staticServices)


export const orgRolesOptions = [{
  key: "Exploitant forestier",
  label: "Exploitant forestier"
}, {
  key: "Scieur",
  label: "Scieur"
},{
  key: "Lamelliste",
  label: "Lamelliste"
}, {
  key: "Négoce",
  label: "Négoce"
},{
  key: "Charpentier",
  label: "Charpentier"
}, {
  key: "Menuisier",
  label: "Menuisier"
},{
  key: "COFOR",
  label: "COFOR"
}, {
  key: "Marque",
  label: "Marque"
},{
  key: "Maitre d'oeuvre",
  label: "Maitre d'oeuvre"
}, {
  key: "Maitre d'ouvrage",
  label: "Maitre d'ouvrage"
}, {
  key: "Financeur",
  label: "Financeur"
}]

export type User = {
  id: string
  email: string
  firstName: string
  lastName: string
}

export type AuthServices = {
  getMemberOf: { returnType: string | undefined },
  isAdmin: { returnType: boolean },
  isCharpentier: { returnType: boolean },
  isCOFOR: { returnType: boolean },
  isExploitant: { returnType: boolean },
  isFinanceur: { returnType: boolean },
  isLamelliste: { returnType: boolean },
  isMarque: { returnType: boolean },
  isMenuisier: { returnType: boolean },
  isMOA: { returnType: boolean },
  isMOE: { returnType: boolean },
  isNegoce: { returnType: boolean },
  isScierie: { returnType: boolean },
  isFirstTransformationActor: { returnType: boolean },
}

const staticServices: KeycloackService<AuthServices, UserRoles> = {

  getMemberOf(keycloak): string | undefined {
    // @ts-ignore
    return keycloak.tokenParsed.memberOf ?? undefined
  },

  isFirstTransformationActor(keycloak): boolean {
    return  keycloak.hasRealmRole("Exploitant forestier") ||  keycloak.hasRealmRole("Scieur")
  },
  isAdmin: (keycloak) => {
    return keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("Marque")
  },
  isCharpentier: (keycloak) => {
    return keycloak.hasRealmRole("Charpentier")
  },
  isCOFOR: (keycloak) => {
    return keycloak.hasRealmRole("COFOR")
  },
  isExploitant: (keycloak) => {
    return keycloak.hasRealmRole("Exploitant forestier")
  },
  isFinanceur: (keycloak) => {
    return keycloak.hasRealmRole("Financeur")
  },
  isLamelliste: (keycloak) => {
    return keycloak.hasRealmRole("Lamelliste")
  },
  isMarque: (keycloak) => {
    return keycloak.hasRealmRole("Marque")
  },
  isMenuisier: (keycloak) => {
    return keycloak.hasRealmRole("Menuisier")
  },
  isMOA: (keycloak) => {
    return keycloak.hasRealmRole("Maitre d'ouvrage")
  },
  isMOE: (keycloak) => {
    return keycloak.hasRealmRole("Maitre d'oeuvre")
  },
  isNegoce: (keycloak) => {
    return keycloak.hasRealmRole("Négoce")
  },
  isScierie: (keycloak) => {
    return keycloak.hasRealmRole("Scieur")
  }
}
