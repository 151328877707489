import { Action, FilterComposableField } from '@smartb/g2'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {useFilters} from "@tracabois/components";

export interface useUserFiltersParams {
    searchOrg?: boolean
    actions?: Action[]
}

export const useUserFilters = (params?: useUserFiltersParams) => {
    const {searchOrg = false, actions} = params ?? {}
    const {t} = useTranslation()
  
    const filters = useMemo((): FilterComposableField[] => [
      {
        key: 'userSearchFilter',
        name: 'search',
        type: 'textField',
        params: { textFieldType: 'search', placeholder: t("userList.nameFilter"), style: {minWidth: "280px"} }
      },
      ...(searchOrg ? [{
        key: 'userSearchOrgFilter',
        name: 'organizationName',
        type: 'textField',
        params: { textFieldType: 'search', placeholder: t("userList.orgNameFilter"), style: {minWidth: "220px"} }
      } as FilterComposableField] : [])
    ], [t, searchOrg])

    return useFilters({filters: filters, actions})
}
