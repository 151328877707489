import {PlatformPageObject} from "@tracabois/template";
import {
  ProductTypeSection,
  WoodSpeciesSection,
  OuvrageTypeSection
} from "@tracabois/domain";
import {Grid} from "@mui/material";

export interface OuvrageListPros {}

export const SettingsView = (_: OuvrageListPros) => {
  return (
    <PlatformPageObject title="Réglages">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <WoodSpeciesSection title="Essences" />
        </Grid>
        <Grid item xs={6}>
          <ProductTypeSection title="Types de produits" />
        </Grid>
        {/*<Grid item xs={6}>*/}
        {/*  <OrganizationTypeSection title="Types d’entreprises" />*/}
        {/*</Grid>*/}
        <Grid item xs={6}>
          <OuvrageTypeSection title="Type d'ouvrage" />
        </Grid>
      </Grid>
    </PlatformPageObject>
  );
}