import React from 'react'
import { BarLoader } from 'react-spinners'
import {makeG2STyles, useTheme} from "@smartb/g2-themes";
import {Box} from "@mui/system";

const useStyles = makeG2STyles()(
  () => ({
    container: {
      width: '100vw',
      height: 'calc(100vh - 2px)',
      backgroundColor: 'white'
    },
    logo: {
      width: '80%',
      maxWidth: '400px'
    }
  }))


export interface LoadingProps {
  img: string
  showOnlyBarLoader?: boolean
}

export const Loading = (props: LoadingProps) => {
  const { img, showOnlyBarLoader } = props
  const {classes} = useStyles()
  const theme = useTheme();
  return (
    <React.Fragment>
      <BarLoader color={theme.colors.primary} width='100%' height='2px' />
      {!showOnlyBarLoader ? (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          className={classes.container}
        >
          <img src={img} className={classes.logo} alt='logo' />
        </Box>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  )
}

