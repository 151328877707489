import {
  OuvrageProductTable,
  ProductCreateCommand,
  ProductCreateFormPopup, ProductModel,
  useProductCreateMutation
} from "../../../product";
import {PageResult, toPage} from "@tracabois/utils";
import {Ouvrage, OuvrageCreatedEvent} from "../../model/ouvrage";
import {usePopup} from "@tracabois/components";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {PlatformSubSection} from "@tracabois/template";
import {Action} from "@smartb/g2";

export interface OuvrageProductSectionProps {
  title: string
  item?: Ouvrage
  isLoading: boolean
  editable: boolean
  onProductClick: (product: ProductModel) => void
  onOuvrageAdded?: (result: OuvrageCreatedEvent) => void
}

const useLabels = () => {
  const {t} = useTranslation()
  return useMemo(() => ({
    addProduct: t("ouvrage.produit.add", "Ajouter ma contribution"),
  }), [t])
}

export const OuvrageProductSection = (props: OuvrageProductSectionProps) => {
  const {title, item, isLoading, editable, onProductClick, onOuvrageAdded} = props
  const createProductPopup = usePopup()
  const labels = useLabels()
  const productPage: PageResult<ProductModel> = toPage(item?.composedBy || [])

  const handlePageChange = () => {
  }
  const [productCreateMutation] = useProductCreateMutation()
  const createProduct = async (command: ProductCreateCommand) => {

    const result = await productCreateMutation(command)
    createProductPopup.close()
    // @ts-ignore
    onOuvrageAdded(result.data)
    return true
  }

  const actions: Action[] = [{
    key: 'moeCreate',
    showIf: () => editable || true,
    onClick: createProductPopup.open,
    label: labels.addProduct
  }]
  return (
    <PlatformSubSection title={title} actions={actions}>
      <OuvrageProductTable
        page={productPage}
        isLoading={isLoading}
        onPageQueryChange={handlePageChange}
        onRowClicked={(row) => onProductClick(row.original)}
      />
      <ProductCreateFormPopup
        ouvrageId={item?.id}
        onClose={createProductPopup.close}
        open={createProductPopup.isOpen}
        onSubmit={createProduct}
      />
    </PlatformSubSection>
  )
}