import {useCallback, useEffect, useMemo} from 'react'
import {FormAction} from '@smartb/g2-forms'
import {BasicProps} from '@smartb/g2-themes'
import {
  RealizationModel,
  RealizationCreateCommand,
  RealizationUpdateCommand,
  Realization
} from "../../../model/realizationModel";
import {FormComposable, FormComposableField, useFeedback} from "@tracabois/components";
import {useFormComposable} from "@smartb/g2";
import urlSlug from 'url-slug'


export interface RealizationFormLabel {
  submit?: string,
  cancel?: string
}

export interface RealizationFormProps extends BasicProps {
  item?: RealizationModel | Realization
  labels?: RealizationFormLabel
  onSubmit?: (item: RealizationCreateCommand | RealizationUpdateCommand) => Promise<boolean> | boolean
  onCancel?: () => void
}

export const RealizationForm = (props: RealizationFormProps) => {
  const {
    item,
    onSubmit,
    onCancel,
  } = props
  const feedback = useFeedback()
  const labels = {
    submit: props.labels?.submit ?? "Créer",
    cancel: props.labels?.cancel ?? "Annuler"
  }

  const onSubmitMemoized = useCallback(
    async (values: RealizationCreateCommand | RealizationUpdateCommand) => {
      if (onSubmit) {
        const result = await onSubmit(values)
        feedback.setFeedback(result)
        return result
      }
      return false
    },
    [onSubmit]
  )

  const fields: FormComposableField[] = useMemo(
    (): FormComposableField[] => [
      {
        key: 'name',
        name: 'name',
        type: 'textField',
        label: 'Nom de la réalisation *',
        validator: (value?: string) => {
          const trimmed = (value ?? '').trim()
          if (!trimmed) return 'Vous devez renseigner le nom' as string
          return undefined
        },
        params:{}
      },
      {
        key: 'coordinates.latitude',
        name: 'coordinates.latitude',
        type: 'textField',
        label: 'Latitude',
        params:{
          placeholder: "43.602829"
        }
      },
      {
        key: 'coordinates.longitude',
        name: 'coordinates.longitude',
        type: 'textField',
        label: 'Longitude',
        params:{
          placeholder: "3.879260"
        }
      },
      {
        key: 'artIndex',
        name: 'artIndex',
        type: 'textField',
        label: 'Index Art',
        params:{}
      },
      {
        key: 'redirectPath',
        name: 'redirectPath',
        type: 'textField',
        label: 'Identifiant unique du lien Qrcode',
        validator: (value?: string) => {
          const trimmed = (value ?? '').trim()
          if (trimmed.length != 0 && trimmed.length < 3) return 'Vous devez renseigner au moins 3 caractères' as string
          return undefined
        },
        params:{
          placeholder: "Ex: centre-culturel-puy-guillaume"
        }
      },
    ],
    []
  )


  const formState = useFormComposable<RealizationCreateCommand | RealizationUpdateCommand>({
    onSubmit: onSubmitMemoized,
    formikConfig: {
      initialValues: {
        id: item?.id,
        name: item?.name,
        coordinates: item?.coordinates,
        // @ts-ignore
        artIndex: item?.artIndex || item?.indicators?.artIndex,
        redirectPath: item?.qrcode?.name
      }
    },
  })
  useEffect(() => {
    !item?.qrcode?.name && formState.values.name && formState.setFieldValue("redirectPath", urlSlug(formState.values.name))
  }, [formState.values.name])

  const actions: FormAction[] =  useMemo(() =>[{
    key: "cancel",
    label: labels.cancel,
    onClick: onCancel,
    variant: "text"
  }, {
    key: "submit",
    label: labels.submit,
    success: feedback.isSuccess,
    fail: feedback.isFailure,
    onClick: formState.submitForm
  }],[onCancel, labels.cancel, labels.submit, feedback, formState])

  return (
    <FormComposable
      fields={fields}
      formState={formState}
      actions={actions}
  />)
}
