import {
  RealizationTable,
  useGetRealizationPageQuery,
  RealizationFormPopup, RealizationId, Realization
} from "@tracabois/domain";
import {PlatformPage} from "@tracabois/template";
import {Button} from "@smartb/g2-components";
import {realizationRoute} from "../route";
import {Row} from "@smartb/g2-layout";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {usePageQuery, usePopup} from "@tracabois/components";
import { useNavigate } from "react-router-dom";

export interface RealizationListPagePros {
}

const useLabels = () => {
  const {t} = useTranslation()

  return useMemo(() => ({
    realizations: t("realization.plurals"),
    createRealization: t("realization.create"),
  }), [t])
}

export const RealizationListPage = (_: RealizationListPagePros) => {
  const popup = usePopup()
  const label = useLabels()
  const navigate = useNavigate();
  const pageQuery = usePageQuery(useGetRealizationPageQuery);

  const actions = (<Button color="primary" onClick={popup.open}>{label.createRealization}</Button>)

  const clickHandler = (row: Row<Realization>) => {
    realizationRoute.goto.viewRealization(row.original.id, navigate)
  }


  const handleCreate = async (id: RealizationId) => {
    realizationRoute.goto.viewRealization(id, navigate)
  }

  return (
    <PlatformPage title={label.realizations}>
      <RealizationTable
        actions={actions}
        onRowClicked={clickHandler}
        page={pageQuery.data}
        isLoading={pageQuery.isLoading}
        onPageQueryChange={pageQuery.setPageQuery}
      />
      <RealizationFormPopup type={"create"} onSaved={handleCreate} onClose={popup.close} open={popup.isOpen} />
    </PlatformPage>
  );
}
