import {Card, CardContent, CardMedia, Typography} from '@mui/material'
import {StatusTag} from '@smartb/g2-notifications'
import { RemovalZone } from '../../index'
import defaultImg from './asset/default.png'

export interface RemovalZoneCardProps {
  item: RemovalZone
}

export const RemovalZoneCard = (props: RemovalZoneCardProps) => {
  const {item} = props
  const img = item.img?.url ?? defaultImg
  return (
    <Card>
      <CardMedia
        component="img"
        height="140"
        image={img}
        alt={item.name}
      />
      <CardContent>
        <Typography gutterBottom variant="subtitle1" color="text.secondary" component="div">
          {item.name}  {item.commune}
        </Typography>
        <Typography variant="body2" color="text.primary">
          {item.woodSpecies.map((value, index) => (<StatusTag key={index} label={value.name} customColor='#9FB0C9'/>))}
        </Typography>
      </CardContent>
    </Card>
  )
}
