import {city} from "tracabois-platform-ouvrageType-domain";

export type OuvrageTypePageQuery = city.smartb.tracabois.platform.s2.ouvrageType.domain.query.OuvrageTypePageQueryDTO

export type OuvrageTypeCreateCommand = city.smartb.tracabois.platform.s2.ouvrageType.domain.command.OuvrageTypeCreateCommandDTO
export type OuvrageTypeCreatedEvent = city.smartb.tracabois.platform.s2.ouvrageType.domain.command.OuvrageTypeCreatedEventDTO

export type OuvrageTypeUpdateCommand = city.smartb.tracabois.platform.s2.ouvrageType.domain.command.OuvrageTypeUpdateCommandDTO
export type OuvrageTypeUpdatedEvent = city.smartb.tracabois.platform.s2.ouvrageType.domain.command.OuvrageTypeUpdatedEventDTO

export type OuvrageTypeDeleteCommand = city.smartb.tracabois.platform.s2.ouvrageType.domain.command.OuvrageTypeDeleteCommandDTO
export type OuvrageTypeDeletedEvent = city.smartb.tracabois.platform.s2.ouvrageType.domain.command.OuvrageTypeDeletedEventDTO

export type OuvrageType = city.smartb.tracabois.platform.s2.ouvrageType.domain.model.OuvrageTypeModelDTO

export const OuvrageTypeState = {
  Exist: city.smartb.tracabois.platform.s2.ouvrageType.domain.automate.OuvrageTypeState.Exists,
  Deleted: city.smartb.tracabois.platform.s2.ouvrageType.domain.automate.OuvrageTypeState.Deleted
}


export type OuvrageTypeId = string
