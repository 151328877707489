import {Stack} from "@mui/material";
import {makeG2STyles} from "@smartb/g2-themes";
import {cx} from "@emotion/css";
import {ProductEvidenceUploadLine} from "./ProductEvidenceUploadLine";
import {ProductModel, ProductSection} from "../../../model/product";
import {
  ProductEvidenceDeletedEvent,
  ProductEvidenceFile,
  ProductEvidenceUploadedEvent, ProduitEvidencePolicies, ProduitEvidencePoliciesResult,
} from "../../../model/productEvidence";
import {useMemo} from "react";
import {useExtendedAuth} from "@tracabois/connect";

const useStyles = makeG2STyles()(
  () => ({
    main: {
      backgroundColor: "#E6E6E6",
      borderRadius: "0px 0px 12px 12px",
      margin: "-20px 0px 20px 0px",
    },
  }))

export interface ProductEvidenceUploadProps {
  product: ProductModel
  section: ProductSection
  evidences?: ProductEvidenceFile[],
  loading: boolean
  readOnly: boolean
  onProductEvidenceUploaded?: (event: ProductEvidenceUploadedEvent|ProductEvidenceDeletedEvent) => void
}
// TODO Use Product Evidence Query Here


export const ProductEvidenceUpload = (props: ProductEvidenceUploadProps) => {
  const {
    product,
    section,
    readOnly,
    loading,
    evidences = [],
    onProductEvidenceUploaded
  } = props
  const {classes} = useStyles()

  const { service } = useExtendedAuth()
  const policies: ProduitEvidencePoliciesResult = useMemo(() => ({
    canUpload: service.executeAuthFunction(ProduitEvidencePolicies.canUpload, product),
    canDelete: service.executeAuthFunction(ProduitEvidencePolicies.canDelete, product),
    canDownload: service.executeAuthFunction(ProduitEvidencePolicies.canDownload, product),
    canList: service.executeAuthFunction(ProduitEvidencePolicies.canList, product),
  } ), [service.executeAuthFunction, product])

  if(!policies.canList) {
    return (<></>)
  }
  return (
    <Stack className={cx(classes.main)}>
      {evidences.map((value, index) =>
        (<ProductEvidenceUploadLine
          key={index}
          productId={product.id}
          evidence={value}
          section={section}
          isLoading={loading}
          readOnly={readOnly}
          onProductEvidenceUploaded={onProductEvidenceUploaded}
          policies={policies}
          deletable
        />)
      )}
      {!loading && !readOnly && policies.canUpload && <ProductEvidenceUploadLine
        productId={product.id}
        section={section}
        isLoading={loading}
        readOnly={readOnly}
        onProductEvidenceUploaded={onProductEvidenceUploaded}
        policies={policies}
      />}
    </Stack>
  )
}
