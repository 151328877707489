import {ProductMetrics} from "../../../../model/product";
import {Unit} from "../../../../../../domain/unit";
import {WoodSpecies} from "../../../../../../referentiels/woodSpecies";
import {FormComposableState} from "@smartb/g2";
import {FormComposable, FormComposableField} from "@tracabois/components";

interface ProductMetricsFieldForm {
  field: FormComposableField[]
  formState: FormComposableState
}

export const ProductMetricsFieldForm = (props: ProductMetricsFieldForm) => {
  const {field, formState} = props
  return (<FormComposable
    fields={field}
    formState={formState}
    sx={{flexGrow: 0.7}}
    fieldsStackProps={{direction: "row", alignItems: "flex-end", sx:{gap: "12px"}}}
    style={{marginRight: "10px"}}
  />)
}

export const withProductField = (units: Unit[], woodSpecies: WoodSpecies[], multipleWoodSpecies: boolean, nestedFieldPrefix: string, item?: Partial<ProductMetrics>) => {
  const species: FormComposableField = multipleWoodSpecies ? {
    key: `${nestedFieldPrefix}.species`,
    name: `${nestedFieldPrefix}.species`,
    type: 'autoComplete',
    params: {
      placeholder: "Essences",
      limitTags: 1,
      options: woodSpecies.map((value) => ({key: value.id, label: value.name})),
      multiple: true,
      style: {
        flexGrow: 1,
      }
    }
  } : {
    key: `${nestedFieldPrefix}.woodSpecies.id`,
    name: `${nestedFieldPrefix}.woodSpecies.id`,
    type: 'autoComplete',
    params: {
      placeholder: "Essences",
      options: woodSpecies.map((value) => ({key: value.id, label: value.name})),
      style: {
        flexGrow: 1,
      }
    }
  }
  const fields = [{
    key: `${nestedFieldPrefix}.quantity`,
    name: `${nestedFieldPrefix}.quantity`,
    type: 'textField',
    defaultValue: item?.quantity,
    params: {
      placeholder: "Qté",
      textFieldType: "number",
      style: {
        width: "70px",
        flexShrink: 0,
      }
    }
  }, {
    key: `${nestedFieldPrefix}.unit`,
    name: `${nestedFieldPrefix}.unit`,
    type: 'autoComplete',
    defaultValue: "m3",
    params: {
      defaultValue: "m3",
      placeholder: "m3",
      options: units.map((value) => ({key: value, label: value})),
      values: ["m3"],
      style: {
        width: "100px",
        flexShrink: 0,
      }
    }
  }, species] as FormComposableField[]
  return {fields}
}
