import {
  EndProductTypeFieldsArray,
  useDestinationEndProductsField
} from "./useDestinationEndProductsField";
import {EndProductModel, Product, ProductTransformation} from "../../../../model/product";
import {DataSelection} from "../useDataSelection";
import {OuvrageRef} from "../../../../../ouvrage";
import {OrganizationRef} from '@smartb/g2-i2'
import {isLastTransformationRolesRoles} from "@tracabois/connect";
import {FormComposableField} from "@tracabois/components";
import {useMemo} from "react";

export interface DestinationFields {
  detailsFields: DestinationEndProductFieldFields
  endProductsFields: EndProductTypeFieldsArray
}

export const useDestinationFields = (
  selection: DataSelection,
  transformation: Partial<ProductTransformation>,
  endProduct: Partial<EndProductModel>[],
  transformationActor?: OrganizationRef
): DestinationFields => {
  const prefix = "destination"
  const organizations = isLastTransformationRolesRoles(transformationActor)
    ? selection.moeOrganizations
    : selection.transformationOrganizations
  const detailsFields = useDestinationEndProductField({
    prefix: prefix,
    organizations: organizations,
    ouvrages: selection.ouvrages,
  })
  const endProductsFields = useDestinationEndProductsField(selection, `${prefix}.endProduct`, endProduct, transformation?.actor?.id)

  return {detailsFields, endProductsFields};
}

interface DestinationEndProductFieldFields {
  clientFields: FormComposableField[]
  ouvrageFields: FormComposableField[]
}

export interface UseDestinationEndProductFieldProps {
  prefix: keyof Product,
  organizations: OrganizationRef[],
  ouvrages: OuvrageRef[]
}

export const useDestinationEndProductField = (props: UseDestinationEndProductFieldProps): DestinationEndProductFieldFields => {
  const { organizations, ouvrages, prefix} = props
  return useMemo(() => {
    const details: FormComposableField[] = [{
      key: `${prefix}.client.id`,
      name: `${prefix}.client.id`,
      type: 'autoComplete',
      label: 'Client',
      params: {
        options: organizations.map((item) => ({key: item.id, label: item.name}))
      }
    }]
    const ouvrageField: FormComposableField[] = [{
      key: 'ouvrage.id',
      name: 'ouvrage.id',
      type: 'autoComplete',
      label: 'Ouvrage',
      params: {
        options: ouvrages.map((item) => ({key: item.id, label: `${item.name}`}))
      }
    }]
    return {
      clientFields: details,
      ouvrageFields: ouvrageField,
    }
  }, [organizations, ouvrages])
}
