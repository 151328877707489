import {Tooltip} from '@smartb/g2-notifications'
import {Ticket as G2Ticket} from '@smartb/g2-components'
import React from "react";
import {makeG2STyles, useTheme} from '@smartb/g2-themes'
import {Skeleton} from "@mui/material";

const useStyles = makeG2STyles()(
  () => ({
    ticket: {
      '& .AruiTicket-root': {
        width: '190px',
        margin: '10px'
      },
    },
  }))

export interface TicketProps {
  icon: React.ReactElement;
  title: string
  indicateur: string | number
  unit?: String
  isLoading?: boolean
  tooltips?: string
}

export const Ticket = (props: TicketProps) => {
  const theme = useTheme()
  const {classes} = useStyles()
  const {icon, title, indicateur, unit, isLoading = false, tooltips} = props

  const coloredIcon = React.cloneElement(icon, {color: theme.colors.primary});
  if(isLoading)
    return <G2Ticket className={classes.ticket} variant="elevated" icon={coloredIcon} title={<Skeleton />} content={<></>}/>;

  const content = !!unit ? `${indicateur} ${unit}` : indicateur
  if(!!tooltips) {
    return (
      <span className={classes.ticket}>
        <Tooltip helperText={tooltips}>
          <G2Ticket className={classes.ticket} variant="elevated" icon={coloredIcon} title={title} content={content}/>
        </Tooltip>
      </span>
    )
  }
  return (
    <span className={classes.ticket}>
      <G2Ticket variant="elevated" icon={coloredIcon} title={title} content={content}/>
    </span>
  )
}
