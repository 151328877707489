import {useMemo, useState} from "react";
import {ProductModel} from "../../../../model/product";
import {DataSelection} from "../useDataSelection";
import {RefByIndex, SourceFieldsArray, withSourceFields} from "./SourceForm/withSourceFields";
import {OrganizationId} from "@smartb/g2-i2";
import {OriginFieldsArray, withOriginFields} from "./OriginForm/withOriginFields";
import {OrganizationRef} from "@tracabois/web-certificate/src/App/model";
import {FormComposableState} from "@smartb/g2";
import {FormComposableArray} from "@tracabois/components";

export interface ProviderFields {
  isFirstTransformationActor: boolean
  addLine: (fieldName: string) => void
  deleteLine: (fieldName: string, index: number) => void
  originFields?: OriginFieldsArray
  sourceFields?: SourceFieldsArray
}

export interface ProductFormProps {
  formState: FormComposableState
  dataSelection: DataSelection
  transformer?: OrganizationRef
}

export const useProviderFields = (props: ProductFormProps): ProviderFields => {
  const {formState, dataSelection, transformer} = props
  const isFirstTransformationActor = useMemo(() => {
    const roleNames = transformer?.roles || []
    return roleNames.includes("Scieur")
  }, [transformer?.roles])

  const lines = useMemo(
    () => {
      // @ts-ignore
      const product: ProductModel | undefined = formState.values
      return (isFirstTransformationActor ?
          product?.origin?.zones?.map((_, idx) => idx) :
          product?.source?.row?.map((_, idx) => idx)
      ) || []
    },
    [formState.values],
  );

  const addLine = (fieldName: string) => {
    FormComposableArray.addLine(formState, fieldName)
  }

  const deleteLine = (fieldName: string, index: number) => {
    FormComposableArray.deleteLine(formState, fieldName, index)
  }
  const [providers, setProvider] = useState<RefByIndex>({})
  const handleProviderChange = (organizationId: OrganizationId, index: number) => {
    const copy = {
      ...providers,
    }
    copy[index] = organizationId
    setProvider(copy)
  }



  const originFields = !!isFirstTransformationActor ? withOriginFields(
    lines,
    "origin.zones",
    dataSelection
  ) : undefined


  const sourceFields = !isFirstTransformationActor ? withSourceFields(
    lines,
    "source.row",
    dataSelection,
    providers,
    handleProviderChange
  ) : undefined

  return {
    isFirstTransformationActor: isFirstTransformationActor,
    addLine: addLine,
    deleteLine: deleteLine,
    originFields: originFields,
    sourceFields: sourceFields,
  }
}

