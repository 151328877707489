import {MenuItem} from '@smartb/g2-components'
import {Option} from '@smartb/g2-forms'
import {Column, Table, TableProps} from '@smartb/g2-layout'
import {BasicProps, MergeMuiElementProps} from '@smartb/g2-themes'
import React from 'react'
import {PlatformSectionTable} from "../PlatformSectionTable/PlatformSectionTable";

export type PlatformBlockedFilters = {
    search?: boolean
}

export interface PlatformTableBasicProps<Data extends {}>  extends BasicProps {
    /**
     * The organizations to pe parsed in the table
     */
    data: Data[]
    /**
     * The definition of columns
     */
    columns: Column<Data>[]
    /**
     * Component to filter table
     */
    filter?: React.ReactNode;
    /**
     * The filters that will be used in the api calls but not rendered for the user.
     * by default they are all set to false
     */
    blockedFilters?: PlatformBlockedFilters
    /**
     * The actions place on the top near the filters
     */
    tableActions?: React.ReactNode
    /**
     * The roles options needed to make the roles select.
     * The default role selected in the form will be the first of the list
     */
    rolesOptions?: Option[]

    /**
     * The current selected page
     */
    page: number
    /**
     * Used for the pagination
     */
    totalPages?: number
    /**
     * The event triggered when the current page change
     */
    onPageChange?: (page: number) => void
    /**
     * The actions available on a organization
     */
    getActions?: (org: Data) => MenuItem<{}>[]
    /**
     * No box elevation if true
     */
    noElevation?: boolean
}

export type PlatformTableProps<DATA extends {}> = MergeMuiElementProps<
    Omit<TableProps<DATA>, 'columns' | 'data' | 'page' | 'onChangePage'>,
    PlatformTableBasicProps<DATA>
    >

export const PlatformTable =  <DATA extends {}>(props: PlatformTableProps<DATA>) => {
    const {
        data,
        columns,
        filter,
        onPageChange,
        page,
        getActions,
        rolesOptions,
        blockedFilters,
        tableActions,
        totalPages,
        noElevation = false,
        ...other
    } = props
    return (
      <PlatformSectionTable noElevation={noElevation}>
            <Table<DATA>
                page={page}
                handlePageChange={onPageChange}
                data={data}
                columns={columns}
                totalPages={totalPages}
                variant='grounded'
                header={
                    filter
                }
                {...other}
            />
        </PlatformSectionTable>
    )
}
